import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import Api, { httpErrorHandler } from "../../../services/server/api";
import { CreateUser } from "../../../services/utils/types/types";
import {  LinearProgress,  Snackbar, Alert } from "@mui/material";
import CreatePerson from "../../../components/create-person";
import moment from "moment";
import {getGenderByChar} from "../../../services/utils/helper";


export default function EditPerson() {
    const params = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const nav = useNavigate();
    const [isLoading, setLoading] = React.useState(true);
    const open = Boolean(anchorEl);
    const [data, setData] = useState<any>();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const api = new Api();
    const navigate = useNavigate();

    const fetchUser = () => {
        setLoading(true);
        api.getPerson(params.id)
            .then((response) => {
                setData(response.data.data[0]);
                setLoading(false);
            })
            .catch((err) => {
                setErrorMessage('Failed to fetch user data.');
                setData([]);
                setLoading(false);
                httpErrorHandler(err);
            });
    };

    useEffect(() => {
        fetchUser();
    }, []);

    const initialValues: CreateUser = {
        name: data?.name,
        gender: getGenderByChar(data?.gender),
        yearOfBirth: data?.yearOfBirth,
        typeOfDocument: data?.typeOfDocument,
        emailAddress: data?.emailAddress,
        callingNum: data?.callingNum,
        fileNo: data?.fileNo,
        serielNo: data?.serielNo,
        immi13Status: data?.immi13Status,
        applicationStatus: data?.applicationStatus,
        notes: data?.notes,
        education: { LEVEL: data?.education?.LEVEL, FIELD: data?.education?.FIELD, UNIVERSITY: data?.education?.UNIVERSITY },
        unStatus: data?.unStatus,
        unhrcCardExpiryDate: data?.unhrcCardExpiryDate,
        mobileNo: data?.mobileNo,
        address: { DISTRICT: data?.address?.DISTRICT, STATE: data?.address?.STATE, DETAIL_ADDRESS: data?.address?.DETAIL_ADDRESS },
        employment: { SALARY: data?.employment?.SALARY, POSITION: data?.employment?.POSITION, DETAILS_OF_EMPLOYER: data?.employment?.DETAILS_OF_EMPLOYER },
        typeOfPass: data?.typeOfPass,
        remarks: data?.remarks,
        dateOfBirth: data?.dateOfBirth,
        relationship: data?.relationship
    };

    const onSubmit = (values: CreateUser) => {
        let dob = moment(new Date(values.dateOfBirth)).format('DD/MM/YYYY');
        values.yearOfBirth = moment(new Date(values.dateOfBirth)).format('YYYY');
        values.dateOfBirth = dob;
        setLoading(true);

        api.UpdatePerson(values, params.id)
            .then(() => {
                setSuccessMessage('Person updated successfully.');
                setLoading(false);
                navigate(-1);
            })
            .catch((err) => {
                setErrorMessage('Failed to update person.');
                if(err.data?.message){
                    setErrorMessage(err.data?.message)
                }else {
                    setErrorMessage('Failed to update person.');
                    httpErrorHandler(err);
                }
                setLoading(false);
            });
    };

    return (
        <>
            {isLoading && <LinearProgress color="secondary" />}

            {data ? (
                <CreatePerson initialValues={initialValues} onSubmit={onSubmit} label={"Edit Person"} />
            ) : null}

            {/* Snackbar for success message */}
            <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage(null)}>
                <Alert onClose={() => setSuccessMessage(null)} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Snackbar for error message */}
            <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
                <Alert onClose={() => setErrorMessage(null)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
