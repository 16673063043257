import { useState, useEffect } from "react";
import Api from "../../services/server/api";
import { Box, Paper, Typography, Grid, Button, LinearProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Bar, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from "chart.js";
import { getGenderByChar, getAgeCategoryByYearOfBirth } from "../../services/utils/helper";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

// Define the User interface
interface User {
    gender?: string;
    yearOfBirth?: string;
    typeOfDocument?: string;
    applicationStatus?: string;
    address?: { STATE?: string };
    education?: { LEVEL?: string };
    employment?: { SALARY?: string };
}

// Define types for statistics objects
type GenderStats = { male: number; female: number };
type Stats = { [key: string]: number };

// Theme for the UI
const theme = createTheme({
    palette: {
        primary: { main: "#1976d2" },
        secondary: { main: "#ff4081" },
    },
    typography: {
        fontFamily: "Roboto, Arial, sans-serif",
        h4: { fontWeight: 600, marginBottom: "1rem" },
        body2: { color: "#6b6b6b" },
    },
});

// Utility function to export data as CSV
const exportToCSV = (data: Stats, filename: string) => {
    const csvRows = [["Category", "Count"]];
    Object.entries(data).forEach(([category, count]) => {
        csvRows.push([category, count.toString()]);
    });
    const csvContent = "data:text/csv;charset=utf-8," + csvRows.map(e => e.join(",")).join("\n");
    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csvContent));
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

// Cache configuration
let cachedData: User[] | null = null;
let cacheTimeStamp: number | null = null;
const CACHE_EXPIRY = 10 * 60 * 1000;

const fetchData = async (): Promise<User[]> => {
    if (cachedData && cacheTimeStamp && Date.now() - cacheTimeStamp < CACHE_EXPIRY) {
        return cachedData;
    }
    const api = new Api();
    try {
        const response = await api.getUserList({ perPage: 3000, offset: 0 });
        cachedData = response.data.data;
        cacheTimeStamp = Date.now();
    } catch (error) {
        console.error("Error fetching data:", error);
        cachedData = [];
    }
    return cachedData || [];
};

// Calculation Functions
const calculateGenderDistribution = (data: User[]): GenderStats => {
    const counts: GenderStats = { male: 0, female: 0 };
    data.forEach(user => {
        const gender = getGenderByChar(user.gender);
        if (gender === "MALE") counts.male++;
        if (gender === "FEMALE") counts.female++;
    });
    return counts;
};

const calculateDocumentTypes = (data: User[]): Stats => {
    const documentCounts: Stats = {};
    data.forEach(user => {
        if (user.typeOfDocument) {
            documentCounts[user.typeOfDocument] = (documentCounts[user.typeOfDocument] || 0) + 1;
        }
    });
    return documentCounts;
};

const calculateApplicationStatuses = (data: User[]): Stats => {
    const statusCounts: Stats = {};
    data.forEach(user => {
        if (user.applicationStatus) {
            statusCounts[user.applicationStatus] = (statusCounts[user.applicationStatus] || 0) + 1;
        }
    });
    return statusCounts;
};

const calculateAgeDistribution = (data: User[]): Stats => {
    const ageCategories: Stats = {
        "SENIOR ADULT": 0,
        "ADULT": 0,
        "YOUNG ADULT": 0,
        "TEENAGER": 0,
        "YOUNG TEENAGER": 0,
        "CHILD": 0,
    };

    data.forEach(user => {
        if (user.yearOfBirth) {
            const category = getAgeCategoryByYearOfBirth(user.yearOfBirth);
            if (ageCategories[category] !== undefined) {
                ageCategories[category]++;
            }
        }
    });

    return ageCategories;
};

const calculateEducationStats = (data: User[]): Stats => {
    const educationCounts: Stats = {};
    data.forEach(user => {
        if (user.education?.LEVEL) {
            educationCounts[user.education.LEVEL] = (educationCounts[user.education.LEVEL] || 0) + 1;
        }
    });
    return educationCounts;
};

const calculateStateDistribution = (data: User[]): Stats => {
    const stateCounts: Stats = {};
    data.forEach(user => {
        const state = user.address?.STATE || "Unknown";
        stateCounts[state] = (stateCounts[state] || 0) + 1;
    });
    return stateCounts;
};

// Main Component
export const AdminDashboard: React.FC = () => {
    const [userData, setUserData] = useState<User[]>([]);
    const [genderStats, setGenderStats] = useState<GenderStats>({ male: 0, female: 0 });
    const [documentStats, setDocumentStats] = useState<Stats>({});
    const [statusStats, setStatusStats] = useState<Stats>({});
    const [ageStats, setAgeStats] = useState<Stats>({});
    const [educationStats, setEducationStats] = useState<Stats>({});
    const [stateStats, setStateStats] = useState<Stats>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            const data = await fetchData();
            setUserData(data);
            setGenderStats(calculateGenderDistribution(data));
            setDocumentStats(calculateDocumentTypes(data));
            setStatusStats(calculateApplicationStatuses(data));
            setAgeStats(calculateAgeDistribution(data));
            setEducationStats(calculateEducationStats(data));
            setStateStats(calculateStateDistribution(data));
            setLoading(false);
        };

        getData();
    }, []);

    // Chart data
    const genderChartData = {
        labels: ["Male", "Female"],
        datasets: [
            {
                label: "Gender Distribution",
                data: [genderStats.male || 0, genderStats.female || 0],
                backgroundColor: ["#42a5f5", "#ec407a"],
                hoverOffset: 4,
            },
        ],
    };

    const documentChartData = {
        labels: Object.keys(documentStats || {}),
        datasets: [
            {
                label: "Document Types",
                data: Object.values(documentStats || {}),
                backgroundColor: "#66bb6a",
            },
        ],
    };

    const statusChartData = {
        labels: Object.keys(statusStats || {}),
        datasets: [
            {
                label: "Application Statuses",
                data: Object.values(statusStats || {}),
                backgroundColor: "#ab47bc",
            },
        ],
    };

    const ageChartData = {
        labels: Object.keys(ageStats || {}),
        datasets: [
            {
                label: "Age Distribution",
                data: Object.values(ageStats || {}),
                backgroundColor: "#ffcc80",
            },
        ],
    };

    const educationChartData = {
        labels: Object.keys(educationStats || {}),
        datasets: [
            {
                label: "Education Levels",
                data: Object.values(educationStats || {}),
                backgroundColor: "#64b5f6",
            },
        ],
    };

    const stateChartData = {
        labels: Object.keys(stateStats || {}),
        datasets: [
            {
                label: "State Distribution",
                data: Object.values(stateStats || {}),
                backgroundColor: "#ffab91",
            },
        ],
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ p: 4 }}>
                {loading && <LinearProgress />}
                <Typography variant="h4">Statistics Dashboard</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Gender Distribution</Typography>
                            <Pie data={genderChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(genderStats, "gender_distribution")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Document Types</Typography>
                            <Bar data={documentChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(documentStats, "document_types")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Application Statuses</Typography>
                            <Bar data={statusChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(statusStats, "application_statuses")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Age Distribution</Typography>
                            <Bar data={ageChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(ageStats, "age_distribution")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">Education Levels</Typography>
                            <Bar data={educationChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(educationStats, "education_levels")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                            <Typography variant="h6">State Distribution</Typography>
                            <Bar data={stateChartData} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />
                            <Button variant="contained" color="primary" fullWidth onClick={() => exportToCSV(stateStats, "state_distribution")}>
                                Export as CSV
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

