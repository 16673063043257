import React from 'react';
import './App.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Api from "./services/server/api";
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";

function App() {
    // Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
    //TODO remove the keys from the code and export them somewhere else
    const firebaseConfig = {
        apiKey: "AIzaSyCaP1qYl5wS9yE1v5Eeb7wXMHU2g9Snda0",
        authDomain: "mahar-app.firebaseapp.com",
        projectId: "mahar-app",
        storageBucket: "mahar-app.appspot.com",
        messagingSenderId: "680104443709",
        appId: "1:680104443709:web:40473c3e7341c8542ccf54",
        measurementId: "G-S8424N3QYE"
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);


  return (
      <AuthProvider>
          <Routes />
      </AuthProvider>

  );
}

export default App;
