import { Add, CloudDownload, CloudUpload,  Download } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { Form } from 'formik';
import React, { useState } from 'react'
import { useParams } from 'react-router';
import Api from '../services/server/api';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
export default function Attachments(user :any) {
  const param= useParams()
  const [open, setOpen] = React.useState(false);
  const [title, setTitle]= useState('')
  const [description, setDescription]= useState('')
  const [file, setFile]= useState <any>(null)

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
      }));

      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
      const api= new Api()
      const handelSubmit=() =>{
        const data= new FormData()
        data.append("file", file)
        data.append("title", title)
        data.append("description", description)
        data.append("isMainPhoto", "false")

        
          if(file){
              api.uploadFile(param.id? param.id : user.user.id, data).then((Response) => window.location.reload())

          }else {
              alert("please select document first!")
          }
        setOpen(false)
      }
        const VisuallyHiddenInput = styled('input')({
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: 1,
          overflow: 'hidden',
          position: 'absolute',
          bottom: 0,
          left: 0,
          whiteSpace: 'nowrap',
          width: 1,
        });
        const handelDelete=(data :string) =>{
          api.deleteFile(data).then((Response) =>  window.location.reload())
        }

return (
    <Box>

        <Typography
            component="h1"
            variant="h5"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, m: 2, fontWeight: "bold" }}
        >
            List of ATTACHMENTS
        </Typography>
        <Button variant="contained" onClick={handleClickOpen} sx={{ margin:2}} color='secondary'>
           <Add/> ATTACH DOCUMENT
        </Button>
    <TableContainer component={Paper}>
    <Table aria-label="simple table" sx={{ margin: 2,marginTop:4 ,width: "90%" }}>
      <TableHead>
        <TableRow>
          <TableCell align="right">TITLE</TableCell>
          <TableCell align="right">TYPE</TableCell>
          <TableCell align="right">DESCRIPTION</TableCell>
          <TableCell align="right"></TableCell>
          <TableCell align="right">ACTION</TableCell>
        
          <TableCell align="center" >

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <DialogTitle id="alert-dialog-title">{"download"}</DialogTitle>
                  <Box sx={{display:"flex", alignItems:"center", justifyItems:"center", gap:3}}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                            >
                          Select Document
                      <VisuallyHiddenInput
                      type="file"
                      onChange={(e : any)=>setFile(e.target.files?.[0]  )}
                        />
            </Button> 
            <Box sx={{display:"flex", flexDirection:"column", gap:2}}>
                  <TextField variant="outlined" placeholder='Title' onChange={(e) => setTitle(e.target.value)} />
                  <TextField variant="outlined" placeholder='description' onChange={(e) => setDescription(e.target.value)} />
            </Box>
    </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type='submit'  onClick={handelSubmit}>
            Upload
          </Button>
        </DialogActions>
                </DialogContent>
        </Dialog>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {user?.user?.files?.map((data : any)=>(
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell align="right">{data.title?data.title: "No Title"}</TableCell>
              <TableCell align="right">{(data.type === ("png"  || "jpg" || "jpeg")) ? ("Image") : data.type.toString().toUpperCase()}</TableCell>
              <TableCell align="right">{data.description}</TableCell>
              <TableCell align="right"><a target='blank' download={data.signedUrl} href={data.signedUrl} >download </a></TableCell>
              <TableCell align="right">
                <Button onClick={() => handelDelete(data.id)} color='error'>
              {user.user.files ?<DeleteIcon />: "" }
                </Button>
              </TableCell>
              {/* <TableCell align="right"><img  width={20} height={20} src={data.signedUrl} alt='.' /> </TableCell> */}
            </TableRow>
            ))}
      </TableBody>
    </Table>
  </TableContainer>
    </Box>
  )

}
