import * as yup from "yup";
import { Form, Formik } from "formik";
import { Box, Button, createTheme, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import TextInput from "./Input/text-input";
import TextSelect from "./Input/text-select";
import DateInput from "./Input/date-input";
import { newMember } from "../services/utils/types/types";
import {
    applicationStatus,
    educationLevels,
    relationshipOptions,
    salaryRanges,
    states
} from "../services/utils/helper";
import { useNavigate } from "react-router-dom";
import * as React from "react"; // Import the useNavigate hook

export default function CreateMember({initialValues, onSubmit, title}:{initialValues:newMember, title:string,  onSubmit:(value:( newMember )) => void}) {
    const navigate = useNavigate(); // Initialize navigate

    const theme = createTheme({
        palette: {
            primary: { main: "#007bff" },
            background: { default: "#f5f5f5" }
        },
        typography: {
            h5: { fontWeight: "bold", color: "#2E3B55" },
            h6: { fontWeight: 600 },
            body1: { color: "#4a4a4a" }
        },
    });

    const validationSchema = yup.object({
        name: yup.string().required("Required"),
        gender: yup.string().required("Required"),
        relationship: yup.string().required("Required"),
        emailAddress: yup.string().email("Invalid email format"),
        typeOfDocument: yup.string().required("Required"),
        mobileNo: yup.string().required("Required"),
    });

    const handleCancel = () => {
        navigate(-1); // Go back to the previous page
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ my: 4, mx: 4 }}>
                <Typography component="h1" variant="h5" sx={{ mb: 4, color: "primary.main" }}>
                    {title}
                </Typography>
                <Paper elevation={2} sx={{ p: 4, borderRadius: 12, boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.05)" }}>
                    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                        {({ handleSubmit, setFieldValue }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {/* Personal Details Section */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mb: 2, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                            Personal Details
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Full Name" name="name" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="Gender" name="gender" option={["MALE", "FEMALE"]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="Relationship Type" name="relationship" option={relationshipOptions} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DateInput label="Date of Birth" name="dateOfBirth" onChange={(value) => setFieldValue("dateOfBirth", value)} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Email Address" name="emailAddress" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Mobile No" name="mobileNo" />
                                    </Grid>


                                    {/* Application Information Section */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mt: 4, mb: 2, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                            Application Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="Applicant Status" name="applicationStatus" option={applicationStatus} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Calling Num" name="callingNum" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput label="File No." name="fileNo" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Type Of Travelling Document" name="typeOfDocument" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Type Of Pass" name="typeOfPass"  />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Seriel No" name="serielNo" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="UN Status (UNHCR Card No.)" name="unStatus" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="UNHRC Card Expiry Date" name="unhrcCardExpiryDate" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Notes" name="notes" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Note(s) / Remark(s)" name="remarks" />
                                    </Grid>


                                    {/* Address Section */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mt: 4, mb: 2, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                            Address
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="District" name="address.DISTRICT" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="State" name="address.STATE" option={states} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput label="Detail Address" name="address.DETAIL_ADDRESS" />
                                    </Grid>

                                    {/* Education Section */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mt: 4, mb: 2, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                            Education
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="Education Level" name="education.LEVEL" option={educationLevels} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Education Field" name="education.FIELD" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="University" name="education.UNIVERSITY" />
                                    </Grid>

                                    {/* Employment Section */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mt: 4, mb: 2, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                            Employment
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextSelect labelInput="Employment Salary" name="employment.SALARY" option={salaryRanges} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput label="Position" name="employment.POSITION" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput label="Details of Employer" name="employment.DETAILS_OF_EMPLOYER" />
                                    </Grid>

                                    {/* Action Buttons */}
                                    <Grid item xs={12} md={6}>
                                        <Button fullWidth variant="contained" size="large" color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button fullWidth variant="outlined" size="large" color="secondary" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
