import {Box, Button, createTheme, Grid, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";
import { map } from "lodash";
import { LabelWithDetails } from "../../../../components/LabelWithDetails";
import * as people from "../../../../services/utils/people.json";
import {getColourByStatus, getFamilyMembersDetails} from "../../../../services/utils/helper";
import {PassportListComp} from "../../../../components/passportListComp";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {applicationType, userType} from "../../../../services/utils/types/types";
import {Link} from "react-router-dom";
import Details from "../../../../components/details";
import SendEmail from "../../../../components/send-email";
import UploadImage from "../../../../components/Input/upload-image";
import Attachments from "../../../../components/attachments";
import { Edit, Person } from "@mui/icons-material";
import HealthInformation from "../../../../components/health-information";
import ListingRequests from "../../../../components/listing-requests";

interface SpouseDetailsProps {
    application?: applicationType;
}

const theme = createTheme();

export const SpouseDetails: React.FC<SpouseDetailsProps> = (props) => {
    let userList: userType[], user: userType | undefined = {};
    if (props && props.application && props.application.person && props.application.person) {
        userList = props.application.person;
        user = userList.find(person => person.relationship ? person.relationship.toLowerCase() === "spouse" : false);
    }


    let immiList: any = [];
    let passportList: any = [];
    let healthInfoList: any = [];
    let requestList : any = [];
    let userID ="";
    if(user&& user.id){
        userID = user.id;
    }
    if (user && user.immi13) {
        immiList = Array.from(user.immi13);
    }
    if (user && user.passport) {
        passportList = Array.from(user.passport);
    }
    if (user && user.health) {
        healthInfoList = Array.from(user.health);
    }
    if (user && user.request) {
        requestList = Array.from(user.request);
    }
    const spouseDetails = getFamilyMembersDetails(user);

  console.log("api",user?.id);

  const emptyDom = <div></div>;
  const dom = (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          m: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginTop:4,
        }}
      >
        <div style={{}}>
          {/* {map(spouseDetails, (i) => (
                    <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                ))} */}
          <Box sx={{ flexGrow: 1, paddingLeft: 2 }}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontWeight={"700"}
                    sx={{ margin: 1 }}
                    color="primary"
                  >
                    Personal Details
                  </Typography>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Link to={`/user_profile/${userID}`}>
                      <Button variant="contained" sx={{gap:1}}>
                        <Edit/>edit
                      </Button>
                    </Link>
                    <Link to={`member/${userID}`}>
                      <Button variant="contained" sx={{gap:1  }} >
                      <Person/>MEMBER
                      </Button>
                    </Link>
                    <SendEmail user={user}/>
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sx={{margin:2, display:"flex", alignItems:"end"}}  >
                                <UploadImage user={user} />
                                </Grid>
              <Details
                title="RELATIONSHIP TYPE"
                children={`${
                  user ? (user.relationship ? user.relationship : "-") : "-"
                }`}
              />
              <Details
                title="Full Name"
                children={`${user ? (user.name ? user.name : "-") : "-"}`}
              />
              <Details
                title="Calling Num"
                children={`${
                  user ? (user.callingNum ? user.callingNum : "-") : "-"
                }`}
              />
              <Details
                title="File No."
                children={`${user ? (user.fileNo ? user.fileNo : "-") : "-"}`}
              />
              <Details
                title="Gender"
                children={`${user ? (user.gender ? user.gender : "-") : "-"}`}
              />
              <Details
                title="Year of Birth"
                children={`${
                  user ? (user.yearOfBirth ? user.yearOfBirth : "-") : "-"
                }`}
              />
              <Details
                title="Age"
                children={`${
                  user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0"
                }`}
              />
              <Details
                title="Age Category"
                children={`${
                  user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0"
                }`}
              />
              <Details
                title="document"
                children={`${
                  user ? (user.typeOfDocument ? user.typeOfDocument : "-") : "-"
                }`}
              />
              <Details
                title="IMM13 STATUS"
                children={`${
                  user ? (user.immi13Status ? user.immi13Status : "-") : "-"
                }`}
              />
                <Details title="APPLICANT STATUS"
                         children={`${user ? user.applicationStatus ? user.applicationStatus : "-" : "-"}`}
                         scolour= {getColourByStatus(user ? user.applicationStatus ? user.applicationStatus : "-" : "-")}/>

                <Details
                title="Notes"
                children={`${user ? (user.notes ? user.notes : "-") : "-"}`}
              />
              <Details
                title="UN STATUS (UNHCR CARD NO.)"
                children={`${
                  user ? (user.unStatus ? user.unStatus : "-") : "-"
                }`}
              />
              <Details
                title="UNHRC CARD EXPIRY DATE"
                children={`${
                  user
                    ? user.unhrcCardExpiryDate
                      ? user.unhrcCardExpiryDate
                      : "-"
                    : "-"
                }`}
              />
              <Details
                title="MOBILE NO."
                children={`${
                  user ? (user.mobileNo ? user.mobileNo : "-") : "-"
                }`}
              />
              <Details
                title="EMAIL ADDRESS"
                children={`${
                  user ? (user.emailAddress ? user.emailAddress : "-") : "-"
                }`}
              />
                <Details
                    title="NOTE(S) / REMARK(S)"
                    children={`${
                        user ? (user?.remarks ? user.remarks : "-") : "-"
                    }`}
                />
              <Grid xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={"700"}
                  sx={{ margin: 1, marginTop: 4 }}
                  color="primary"
                >
                  Address
                </Typography>
              </Grid>
              <Details
                title="DISTRICT"
                children={`${
                  user
                    ? user.address
                      ? user.address.DISTRICT
                        ? user.address.DISTRICT
                        : "-"
                      : "-"
                    : "-"
                }`}
              />
              <Details
                title="STATE"
                children={`${
                  user
                    ? user.address
                      ? user.address.STATE
                        ? user.address.STATE
                        : "-"
                      : "-"
                    : "-"
                }`}
              />
              <Details
                title="Detailed Address"
                children={`${
                  user
                    ? user.address
                      ? user.address.DETAIL_ADDRESS
                        ? user.address.DETAIL_ADDRESS
                        : "-"
                      : "-"
                    : "-"
                }`}
              />
              <Grid xs={12}>
                <Typography
                  variant="h6"
                  fontWeight={"700"}
                  sx={{ margin: 1, marginTop: 4 }}
                  color="primary"
                >
                  Education
                </Typography>
              </Grid>
                <Details title="Education FIELD"
                         children={`${user ? (user.education ? user.education.FIELD ? user.education.FIELD : '-' : '-') : '-'}`}/>
                <Details title="LEVEL"
                         children={`${user ? (user.education ? user.education.LEVEL ? user.education.LEVEL : '-' : '-') : '-'}`}/>
                <Details title="UNIVERSITY"
                         children={`${user ? (user.education ? user.education.UNIVERSITY ? user.education.UNIVERSITY : '-' : '-') : '-'}`}/>

                <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                          color="primary">Employment</Typography></Grid>
                <Details title="DETAILS OF MPLOYER"
                         children={`${user ? (user.employment ? user.employment.DETAILS_OF_EMPLOYER ? user.employment.DETAILS_OF_EMPLOYER : '-' : '-') : '-'}`}/>
                <Details title="POSITION"
                         children={`${user ? (user.employment ? user.employment.POSITION ? user.employment.POSITION : '-' : '-') : '-'}`}/>
                <Details title="SALARY"
                         children={`${user ? (user.employment ? user.employment.SALARY ? user.employment.SALARY : '-' : '-') : '-'}`}/>
            </Grid>
          </Box>
        </div>

        {/*List of passports*/}
        <PassportListComp
          passportList={passportList}
          personID={userID}
        ></PassportListComp>

        {/*List of IMMI13 cards*/}

        <ImmiCardsComp
          immiCardList={immiList}
          personID={userID}
        ></ImmiCardsComp>
                <ListingRequests  requestsList={requestList}
                                 personId={userID}/>
                <HealthInformation healthInfoList ={healthInfoList}
                                   personId={userID}/>
          <Attachments user={user}/>
      </Box>
    </ThemeProvider>
  );

  if (!(user && user.name && user.name.length > 3)) {
    return emptyDom;
  } else {
    return dom;
  }

};