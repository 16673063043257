import * as React from "react";

import { createTheme } from "@mui/material";
import { newMember } from "../../../services/utils/types/types";
import Api, {httpErrorHandler} from "../../../services/server/api";
import {useNavigate} from "react-router";
import moment from "moment/moment";
import CreateMember from "../../../components/create-member";



const theme = createTheme({
  palette: {
    primary: {
      main: "#8a009b",
    },
  },
});

export default function AddMember() {
  const queryParameters = new URLSearchParams(window.location.search)
  //TODO check if appID exist if not then throw error
  const appId = queryParameters.get("id");
  const navigate = useNavigate();


  const initialValues: newMember = {
    applicationId: appId || "",
    relationship: "",
    name: "",
    gender: "",
    yearOfBirth: "",
    typeOfDocument: "",
    emailAddress: "",
    callingNum: "",
    fileNo: "",
    serielNo: "",
    immi13Status: "",
    applicationStatus: "",
    notes: "",
    education: {LEVEL:"",FIELD:"",UNIVERSITY:""},
    unStatus: "",
    unhrcCardExpiryDate: "",
    mobileNo: "",
    address: {DISTRICT:"",STATE:"",DETAIL_ADDRESS:""},
    employment: 
        {SALARY:"",POSITION:"",DETAILS_OF_EMPLOYER:""},
    typeOfPass: "",
    yearOfChange: "",
    remarks: "",
    dateOfBirth:""
  };
  const api = new Api();
  const onSubmit = (values: newMember) => {
    let dob= moment(new Date(values.dateOfBirth)).format('DD/MM/YYYY');
    values.yearOfBirth = moment(new Date(values.dateOfBirth)).format('YYYY');
    values.dateOfBirth=dob;

      api.addNewPerson(values)
          .catch(httpErrorHandler)
          .then(() => {
        // TODO show success message
        navigate(-1);
    });
  }
 

  return (

      <CreateMember initialValues={initialValues} onSubmit={onSubmit}  title="Add New Member"/>

  );
};
