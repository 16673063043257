import * as React from 'react';
import {Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, Container,
    createTheme, CssBaseline, FormControlLabel, Grid,
    Link,
    Paper, TextField, ThemeProvider, Typography } from '@mui/material';
import Api from "../../services/server/api";
import {useAuth} from "../../provider/authProvider";
import {createSearchParams, useNavigate} from "react-router-dom";
import {USERPROFILE} from "../../constants/routes";

interface LogoutProps {

}

const theme = createTheme();

export const Logout: React.FC<LogoutProps> = (props) => {
    const { setToken } = useAuth();
    const api = new Api();
    let navigate = useNavigate();




    const handleLogout = () => {
        setToken();
        navigate("/", { replace: true });
    };
    handleLogout();

  return (
      <ThemeProvider theme={theme}>
      </ThemeProvider>
  );
};