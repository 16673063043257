import * as React from 'react';
import { Alert, Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, Container, createTheme, CssBaseline, FormControlLabel, Grid, Link, Paper, Snackbar, TextField, ThemeProvider, Typography } from '@mui/material';
import Api, { httpErrorHandler } from "../../services/server/api";
import { useAuth } from "../../provider/authProvider";
import { createSearchParams, useNavigate } from "react-router-dom";
import { USERPROFILE } from "../../constants/routes";
import { login } from '../../services/utils/types/types';
import { values } from 'lodash';
import * as yup from 'yup'
import { Form, Formik } from 'formik';
import TextInput from '../../components/Input/text-input';

interface LoginProps {}

const theme = createTheme();

export const Login: React.FC<LoginProps> = (props) => {
    const { setToken } = useAuth();
    const [successMessage, setSuccessMessage] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const api = new Api();
    let navigate = useNavigate();

    const initialValues: login = {
        username: "",
        password: "",
    };

    const onSubmit = (values: login) => {
        api.postLogin(values)
            .then((response) => {
                if (response && response.data.token) {
                    setToken(response.data.token);
                    localStorage.setItem('email', response.data.email);
                    setSuccessMessage(true);
                    navigate('/view_application');
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (err?.response?.data?.statusCode === 401) {
                    setErrorMessage("The username or password you entered is wrong!");
                } else {
                    setErrorMessage(err?.response?.data?.message || 'Login Failed');
                }
                setToken(null);
                navigate("/login", { replace: true });
            });
    };

    const handleSnackbarClose = () => {
        setSuccessMessage(false);
        setErrorMessage(null);
    };

    const validationSchema = yup.object({
        password: yup.string().required("required"),
        username: yup.string().required("required"),
    });

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm"> {/* Responsive container */}
                <CssBaseline />
                <Box
                    sx={{
                        my: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Paper
                        sx={{
                            p: { xs: 2, md: 4 },  // Padding varies based on screen size
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: { xs: '100%', sm: '400px', md: '500px' }, // Responsive width
                        }}
                        elevation={2}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} />
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                            {({ handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 2,
                                            marginTop: 3,
                                            marginBottom: 3,
                                            width: "100%", // Use full width of parent
                                        }}
                                    >
                                        <TextInput label="User Name" name="username" type="text" />
                                        <TextInput label="Password" name="password" type="password" />
                                    </Box>
                                    <FormControlLabel
                                        control={<Checkbox value="remember" color="primary" />}
                                        label="Remember me"
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Sign In
                                    </Button>
                                    <Grid container>
                                        <Grid item xs>
                                            <Link href="#" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            <Link href="#" variant="body2">
                                                {"Don't have an account? Sign Up"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </Box>
                <Snackbar open={successMessage} autoHideDuration={3000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                        Logging in!
                    </Alert>
                </Snackbar>

                {errorMessage && (
                    <Snackbar open={!!errorMessage} autoHideDuration={5000} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
            </Container>
        </ThemeProvider>
    );
};
