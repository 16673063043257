import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { sendEmail } from '../services/utils/types/types';
import { Grid, Snackbar, Alert } from '@mui/material';
import TextInput from './Input/text-input';
import Api from '../services/server/api';
import 'react-quill/dist/quill.snow.css'; // Import styles for the editor
import ReactQuill from 'react-quill';
import { Send } from '@mui/icons-material';

export default function SendEmail(user: any) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");

    const api = new Api();

    const initialValues: sendEmail = {
        personId: user?.user?.id || "",
        subject: "",
        message: ""
    };
    const handleClose = () => {
        setOpen(false);
        setSuccessMessage("");
        setErrorMessage("");
    };
    const handleClickOpen = () => {
        setOpen(true);
    };


    const validationSchema = yup.object({
        subject: yup.string().required("Subject is required"),
    });

    const onSubmit = (values: sendEmail, { resetForm }: any) => {
        if(values.message.length<1)
            setErrorMessage("Message can't be empty")
        else {

        setLoading(true);
        api.sendEmail(values)
            .then((res) => {
                setSuccessMessage("Email sent successfully!");
                setErrorMessage("");
                resetForm();  // Clear form on success
            })
            .catch((error) => {
                setErrorMessage("Failed to send email. Please try again.");
                setSuccessMessage("");
            })
            .finally(() => {
                setLoading(false);
               handleClose();
            });
        }


    };




    return (
        <>
            <Button variant="contained" onClick={handleClickOpen} sx={{ width: 160, margin: 1, gap:1 }}>
                <Send/>Send Email
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">{"SEND EMAIL"}</DialogTitle>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ handleSubmit, setFieldValue, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <TextInput label="Subject" name='subject' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReactQuill
                                            value={values.message}
                                            onChange={(value : any) => setFieldValue('message', value)}
                                            placeholder="Write your message here..."
                                        />
                                    </Grid>
                                </Grid>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button type='submit' disabled={isLoading}>
                                        {isLoading ? "Sending..." : "Send"}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            {/* Success Snackbar */}
            <Snackbar
                open={Boolean(successMessage)}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage("")}
            >
                <Alert onClose={() => setSuccessMessage("")} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>

            {/* Error Snackbar */}
            <Snackbar
                open={Boolean(errorMessage)}
                autoHideDuration={6000}
                onClose={() => setErrorMessage("")}
            >
                <Alert onClose={() => setErrorMessage("")} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
