import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField } from 'formik';
import { Typography } from '@mui/material';
import dayjs, {Dayjs} from 'dayjs';

export default function DateInput({label, name, onChange}:{label:string, name:string, onChange:(value:string | null) => void}) {
    const [field, meta] = useField(name);
    const [date, setDate] = React.useState<any>(dayjs(field.value, "DD-MM-YYYY"));


    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}  >
        <DatePicker
            label={label}
            format="DD/MM/YYYY"
            sx={{width:"100%"}}
            value={date}
            onChange={onChange}
        />

      <Typography  variant='subtitle2'  color={"error"} >
      {meta.touched && meta.error ? meta.error: ""}
      </Typography>
    </LocalizationProvider>
  );
}