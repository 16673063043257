import moment from "moment";

export const getAgeByYearOfBirth = (yearOfBirth) => {
    const year = parseInt(yearOfBirth,10);
    const today = new Date();
    const age = today.getFullYear()-year;
    return age.toString();

}
export const isActive = (date) => {
    if(date && date.length>0){
        return moment(date, 'DD.MM.YYYY').isAfter(moment.now());
    }else {
        return false;
    }
}

export const getAgeCategoryByYearOfBirth = (yearOfBirth) => {
    const age = getAgeByYearOfBirth(yearOfBirth);
   if (age >60){
       return "Senior Adult".toUpperCase()
   }else if (age >=36 && age <=60){
       return "Adult".toUpperCase();
   }else if(age >=19 && age<36){
       return "Young Adult".toUpperCase();
   }else if (age >=13 && age<19 ){
       return "Teenager".toUpperCase();
   }else if (age >=7 && age<13){
       return "Young Teenager".toUpperCase();
   }else {
       return  "Child".toUpperCase();
   }
}

export const getGenderByChar = (gender) => {
    let output = gender == 'L' ? 'Male' : gender == 'P' ? 'Female' : gender == 'F' ? 'Female' : gender == 'M' ? 'Male' : gender;
    output=output?output.toUpperCase():null;
    return output;
}

export const getFamilyMembersDetails = (member) => {
    const familyMemberDetail = [
        { title: `Relationship Type`, detail: member?member.relationship?member.relationship.toUpperCase():'-':'-'},
        { title: `Full Name`, detail: member?member.name?member.name:'-':'-'},
        { title: `Calling Num`, detail: member?member.callingNum?member.callingNum:'-':'-'},
        // { title: `Batch`, detail: '1' },
        { title: `File No.`, detail: member?member.fileNo?member.fileNo:'-':'-'},
        { title: `gender`, detail: member?member.gender?getGenderByChar(member.gender):'-':'-' },
        { title: `Year of Birth`, detail:member?member.yearOfBirth?member.yearOfBirth:'-':'-'},
        { title: `Age`, detail: getAgeByYearOfBirth(member?member.yearOfBirth?member.yearOfBirth:'0':'0') },
        { title: `Age Category`, detail: getAgeCategoryByYearOfBirth(member?member.yearOfBirth?member.yearOfBirth:'0':'0') },
        { title: `document`, detail: member?member.typeOfDocument?member.typeOfDocument:'-':'-'},
        { title: `IMM13 STATUS`, detail: member?member.immi13Status?member.immi13Status:'-':'-' },
        { title: `APPLICANT STATUS`, detail: member?member.applicationStatus?member.applicationStatus:'-':'-' },
        { title: `Notes`, detail: member?member.notes?member.notes:'-':'-' },
        { title: `UN STATUS (UNHCR CARD NO.)`, detail: member?member.unStatus?member.unStatus:'-':'-' },
        { title: `UNHRC CARD EXPIRY DATE`, detail: member?member.unhrcCardExpiryDate?member.unhrcCardExpiryDate:'-':'-' },
        { title: `MOBILE NO.`, detail: member?member.mobileNo?member.mobileNo:'-':'-' },
        { title: `EMAIL ADDRESS`, detail: member?member.emailAddress?member.emailAddress:'-':'-'},
        { title: `DISTRICT`, detail: member?member.address?member.address.DISTRICT?member.address.DISTRICT:'-':'-':'-'},
        { title: `STATE`, detail: member?member.address?member.address.STATE?member.address.STATE:'-':'-':'-' },
        { title: `Detailed Address`, detail: member?member.address?member.address.DETAIL_ADDRESS?member.address.DETAIL_ADDRESS:'-':'-':'-' },
        { title: `NOTE(S) / REMARK(S)`, detail: member?member.remarks?member.remarks:'-':'-'},
    ]
    return familyMemberDetail;
}

export const states = [
    "PERLIS",
    "KEDAH",
    "PENANG",
    "KELANTAN",
    "TERENGGANU",
    "PAHANG",
    "SELANGOR",
    "NEGERI SEMBILAN",
    "MELAKA",
    "JOHOR",
    "PERAK",
    "SABAH",
    "SARAWAK",
    "KUALA LUMPUR"
];
export const imm13Status =[
    "SENT FOR RENEWAL",
    "READY TO BE COLLECTED",
    "COLLECTED",
    "EXPIRED",
    "UNCOLLECTED",
    "EXIT PROGRAMME",
    "CHANGE PASS",
    "ACTIVE"
];
export const applicationStatus = [
    "ACTIVE",
    "EXIT COUNTRY",
    "DECEASED",
    "CHANGE PASS",
    "EXPIRED",
    "IN PROGRAM"
];
export const salaryRanges = [
    "Less than RM 1500",
    "RM 1500 to RM 2500",
    "RM 2500 to RM 5000",
    "Above RM 5000"
];
export const educationLevels = [
    "PRIMARY SCHOOL",
    "HIGH SCHOOL",
    "COLLEGE OR DIPLOMA",
    "DEGREE",
    "MASTER",
    "PHD"
];
export const relationshipOptions = [
    "MAIN APPLICANT",
    "FATHER",
    "MOTHER",
    "SPOUSE",
    "SON",
    "DAUGHTER",
    "BROTHER",
    "SISTER"
];

export const statusColorMap = {
    "ACTIVE": 'green',
    "UNCOLLECTED": 'yellow',
    'MIGRATE 3RD COUNTRY': 'lightblue',
    'CHANGE PASS': 'lightgreen',
    "DECEASED": 'blue',
    'EXIT PROGRAMME': 'red',
    "EXIT COUNTRY": 'red',
    'RE-APPLY': 'lightcoral',
};
 export const getColourByStatus  = (appStatus) => {
     if(appStatus){
         return statusColorMap[appStatus]
     }else
     return  "";
 }


// Function to format mobile number with spacing
export const formatMalaysianPhoneNumber = (phoneNumber) => {
    // Remove spaces, dashes, and other non-numeric characters
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Check if the number is a valid Malaysian number
    if (cleaned.length >= 9 && cleaned.length <= 12) {
        // Format the number based on conditions
        if (cleaned.startsWith('60')) {
            return formatWithSpacing(`+${cleaned}`);
        } else if (cleaned.startsWith('0')) {
            return formatWithSpacing(`+60${cleaned.substring(1)}`);
        } else if (cleaned.startsWith('+60')) {
            return formatWithSpacing(cleaned);
        }
    }

    // Return the original or a formatted string if it doesn't meet criteria
    return '-';
};

// Helper function to add spacing to Malaysian phone number
const formatWithSpacing = (formattedNumber) => {
    // Remove the country code for formatting
    let localNumber = formattedNumber.replace('+60', '');

    if (localNumber.startsWith('1')) {
        // Mobile number format: +60 1X-XXX XXXX
        return `+60 ${localNumber.slice(0, 2)}-${localNumber.slice(2, 5)} ${localNumber.slice(5)}`;
    } else {
        // Landline format: +60 X-XXXX XXXX
        return `+60 ${localNumber.slice(0, 1)}-${localNumber.slice(1, 5)} ${localNumber.slice(5)}`;
    }
}
