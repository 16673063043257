import * as React from "react";
import { map } from "lodash";
import { Avatar, Box, Button, Chip, createTheme, FormLabel, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useEffect, useRef } from "react";

import Divider from "@mui/material/Divider";
import { applicationType, userType } from "../../services/utils/types/types";
import Api from "../../services/server/api";
import { getAgeByYearOfBirth, getAgeCategoryByYearOfBirth, getColourByStatus, getFamilyMembersDetails, getGenderByChar, isActive } from "../../services/utils/helper";
import { LabelWithDetails } from "../../components/LabelWithDetails";
import { Document, Image, Page, Text, StyleSheet, PDFViewer, View } from "@react-pdf/renderer";
import { text } from "stream/consumers";
import Details from "../../components/details";
import { ImmiCardsComp } from "../../components/immiCardsComp";

// const theme = createTheme();

interface PrintProfileProps {}

export const PrintApplication: React.FC<PrintProfileProps> = (props) => {
    let application: applicationType = {
        applicationStatus: "",
        callingNum: "",
        emailAddress: "",
        mobileNo: "",
        name: "",
        person: []
    };

    const [value, setValue] = React.useState(0);
    const [isLoading, setLoading] = React.useState(false);
    const [applicationData, setApplication] = React.useState(application);

    const queryParameters = new URLSearchParams(window.location.search)
    const appId = queryParameters.get("id");
    const getApplication = (params: any) => {
        setLoading(true);
        const api = new Api();
        api
            .getApplication(params)
            .then((response) => {
                application = response.data[0];
                setApplication(application);
                setLoading(false);
                // handlePrint();
            })
            .catch((err) => {
                console.log(err);
                setApplication(application);
                setLoading(false);
            });
    };


    useEffect(() => {
        getApplication({id: appId});
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // @ts-ignore
    let userList: userType[] | [] = applicationData.person;

    let newArr = userList.map((element: userType, index) => {
        const item = {
            doms: element,
            immi13: element.immi13,
            passport: element.passport,
            userID: element.id
        }
        return item;
    });

    let immiList: any = [];
    let passportList: any = [];
    let userID = "";
    const styles= StyleSheet.create({
        body:{
            paddingTop:35,
            paddingBottom:65,
            paddingHorizontal:35
        },image:{
            width:60,
            height:60,
            marginTop:10
        },header:{
            fontSize:14,
            fontWeight:"black",
            textAlign:"center",
            color:"black"
        },text:{
            fontSize:10, 
            paddingTop:4,
        },pageNumber:{
            position:"absolute",
            fontSize:12,
            bottom:30,
            left:0,
            right:0,
            textAlign:"center",
            color:"gray"
        },title:{
            fontSize:12,
           fontWeight: 'black',
            marginTop:20
        },data:{
            fontSize:10,
            marginTop:2,
        },test:{
            fontSize:16,
            fontWeight:"bold",
            color: "#007bff",
                marginTop:25,
                width:"100%"
        },member:{
                fontSize:14,
                color:"#777",
                marginTop:20,
        },box:{
                display:"flex",
                flexDirection:"row",
                gap:2,
                justifyContent:"space-between",
                flexWrap:"wrap"
        },view:{  
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start",
                width:"30%",
                flexWrap:"wrap"
            },
    })
    console.log("data", newArr[0]?.immi13?.[0]);
    
    return (
        <Document>
            {newArr.map((data, index) =>{
                return(
                <Page style={styles.body} size="A4">
                <View>
                    <View style={{display:"flex", alignItems:"center", flexDirection:"row", gap:10}}>
            <Image src={"/logo192.jpg"} style={styles.image}/>
                    <View>
            <Text fixed style={styles.header} >MALAYSIAN HUMANITARIAN AID AND RELIEF (MAHAR)</Text>
                 <Text style={styles.text}>Profile Details of Syrian Migrants Temporary Relocation Programme Candidate PPSMS / IMM13</Text>
                    </View>
                    </View>
                    <Text style={styles.member}>Family Member: {index + 1}</Text>
                 <Text style={styles.test} >Personal Details</Text>
                        <Image source={data.doms.mainPhoto || "https://material-ui.com/broken-image.jpg"} style={styles.image}/>

                        <View style={styles.box} wrap  >
                        <View style={styles.view}>

                            <Text style={styles.title}>Full Name</Text>
                            <Text style={styles.data}>{data?.doms?.name  || "-" }</Text>
                                </View>
                              
                                <View style={styles.view}>

                        <Text style={styles.title}>Gender</Text>
                            <Text style={styles.data}>{getGenderByChar(data.doms.gender) || "-"}</Text>
                              </View>
                              <View style={styles.view}>

                       
                        <Text style={styles.title}>Calling Num</Text>
                            <Text style={styles.data}>{data.doms.callingNum || "-"}</Text>
                                    </View>
                                    <View style={styles.view}>
                        <Text style={styles.title}>File No.</Text>
                            <Text style={styles.data}>{data.doms.fileNo || "-"} </Text>
                               </View>
                                    <View style={styles.view}>
                        <Text style={styles.title}> Relationship Type</Text>
                            <Text style={styles.data}>{data.doms.relationship || "-"} </Text>
                               </View>
                               <View style={styles.view}>

                        <Text style={styles.title}>Year of Birth</Text>
                            <Text style={styles.data}>{data.doms.yearOfBirth || "-"}</Text>
                                </View>
                                <View style={styles.view}>


                        <Text style={styles.title}>Age</Text>
                            <Text style={styles.data}>{getAgeByYearOfBirth(data.doms.yearOfBirth) || "-"}</Text>
                                </View>

                                <View style={styles.view}>


                        <Text style={styles.title}>Age Category</Text>
                            <Text style={styles.data}>{getAgeCategoryByYearOfBirth(data.doms.yearOfBirth) || "-"}</Text>
                                </View>
                                <View style={styles.view}>
                        <Text style={styles.title}>Document</Text>
                            <Text style={styles.data}>{data.doms.typeOfDocument || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>IMM13 Status</Text>
                            <Text style={styles.data}>{data.doms.immi13Status || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Applicant Status</Text>
                            <Text style={styles.data}>{data.doms.applicationStatus || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Notes</Text>
                            <Text style={styles.data}>{data.doms.notes || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>UN Status (UNHCR Card No.)</Text>
                            <Text style={styles.data}>{data.doms.unStatus || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>UNHRC Card Expiry Date</Text>
                            <Text style={styles.data}>{data.doms.unhrcCardExpiryDate || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Mobile No.</Text>
                            <Text style={styles.data}>{data.doms.mobileNo || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Email Address</Text>
                            <Text style={styles.data}>{data.doms.emailAddress || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Note(s) / Remark(s)</Text>
                            <Text style={styles.data}>{data.doms.notes || "-"}</Text>
                                </View>
                                <Text style={styles.test}>Address</Text>
                                <View style={styles.view}>
                        <Text style={styles.title}>District</Text>
                            <Text style={styles.data}>{data.doms.address?.DISTRICT || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>State</Text>
                            <Text style={styles.data}>{data.doms.address?.STATE || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>Detailed Address</Text>
                            <Text style={styles.data}>{data.doms.address?.DETAIL_ADDRESS || "-"}</Text>
                                </View>


                                <Text style={styles.test}>Education</Text>
                                <View style={styles.view}>
                        <Text style={styles.title}>Education Field</Text>
                            <Text style={styles.data}>{data.doms.education?.FIELD || "-"}</Text>
                                </View>

                                <View style={styles.view}>
                        <Text style={styles.title}>Level</Text>
                            <Text style={styles.data}>{data.doms.education?.LEVEL || "-"}</Text>
                                </View>
                                <View style={styles.view}>

                        <Text style={styles.title}>University</Text>
                            <Text style={styles.data}>{data.doms.education?.UNIVERSITY || "-"}</Text>
                                </View>

                            <Text style={styles.test}>Employment</Text>
                            <View style={styles.view}>

                        <Text style={styles.title}>Details of Employer</Text>
                            <Text style={styles.data}>{data.doms.employment?.DETAILS_OF_EMPLOYER || "-"}</Text>
                            </View>
                            <View style={styles.view}>

                        <Text style={styles.title}>Position</Text>
                            <Text style={styles.data}>{data.doms.employment?.POSITION || "-"}</Text>
                            </View>
                            <View style={styles.view}>

                        <Text style={styles.title}>Salary</Text>
                            <Text style={styles.data}>{data.doms.employment?.SALARY || "-"}</Text>
                            </View>
                        
                                    <Text style={styles.test}>Latest IMMI13 card</Text>
                            <View style={styles.view}>
                                <Text style={styles.title}>CARD NUMBER</Text>
                                {/* <Text style={styles.data}>{data?.immi13?.[0]?.cardNumber || "-"}</Text> */}
                                <Text style={styles.data}>{data?.immi13?.[data?.immi13.length-1]?.cardNumber || "-"}</Text>

                            </View>
                            <View style={styles.view}>
                                <Text style={styles.title}>EXPIRY DATE</Text>
                                {/* <Text style={styles.data}>{data?.immi13?.[0]?.expiryDate || "-"}</Text> */}
                                <Text style={styles.data}>{data?.immi13?.[data?.immi13.length-1]?.expiryDate || "-"}</Text>

                            </View>
                            <View style={styles.view}>
                                <Text style={styles.title}>STATUS</Text>
                                <Text style={styles.data}>
                                {/* {data?.immi13?.[0]?.isOriginal === "true" ? "ORGINAL" : data?.immi13?.[0].isOriginal.toUpperCase() } */}
                                {data?.immi13?.[data?.immi13.length-1]?.isOriginal === "true" ? "ORGINAL" : data?.immi13?.[data?.immi13.length-1]?.isOriginal.toLocaleUpperCase() || "-"}

                                </Text>
                            </View>
                            <View style={styles.view}>
                                <Text style={styles.title}>EXPIRY</Text>
                                <Text style={styles.data}>
                                {!isActive(data?.immi13?.[data?.immi13.length-1]?.expiryDate  || "-")
                                // (data?.immi13?.[0]?.expiryDate ? data?.immi13?.[0]?.expiryDate : "-") 
                                && "EXPIRED"}
                                {isActive(data?.immi13?.[data?.immi13.length-1]?.expiryDate  || "-") && "ACTIVE"}
                                </Text>
                            </View>
                                    <Text style={styles.test}>Latest Passports</Text>
                            <View style={styles.view}>
                                <Text style={styles.title}>PASSPORT NUMBER</Text>
                                {/* <Text style={styles.data}>{data?.passport?.[0].passportNo || "-"}</Text> */}
                                <Text style={styles.data}>{data?.passport?.[data?.passport.length-1]?.passportNo || "-"}</Text>+
                            </View>
                            <View style={styles.view}>
                                <Text style={styles.title}>EXPIRY DATE</Text>
                                {/* <Text style={styles.data}>{data?.passport?.[0]?.expiryDate || "-"}</Text> */}
                                <Text style={styles.data}>{data?.passport?.[data?.passport.length-1]?.expiryDate || "-"}</Text>
                            </View>
                            <View style={styles.view}>
                                <Text style={styles.title}>Status</Text>
                                <Text style={styles.data}>
                                {!isActive(data?.passport?.[data?.passport.length-1]?.expiryDate  || "-") && (
                            "Expired" 
                        )}
                        {
                        // isActive(data?.passport?.[0]?.expiryDate  ? data?.passport?.[0]?.expiryDate : "-")
                        isActive(data?.passport?.[data?.passport.length-1]?.expiryDate  || "-") 
                        && (
                            "Active" 
                        )}
                                </Text>
                            </View>
                        </View>
                </View>
                <Text
                style={styles.pageNumber}
                render={({pageNumber, totalPages}) => `${pageNumber}/${totalPages}`}
                ></Text>         
            </Page>
            )})}
        </Document>
    );
};
