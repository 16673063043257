import * as React from "react";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Collapse,
  createTheme,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  TableCell,
  TextField,
  Modal,
} from "@mui/material";
import { map } from "lodash";
import { isActive } from "../services/utils/helper";
import { PassportType } from "../services/utils/types/types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import dayjs from "dayjs";
import Api from "../services/server/api";

const theme = createTheme();
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  passportNo: Yup.string().required("Passport Number is required"),
  expiryDate: Yup.date().required("Expiry date is required"),
});

interface PassportListCompProps {
  passportList: Array<PassportType>;
  personID: string;
}

export const PassportListComp: React.FC<PassportListCompProps> = ({ passportList, personID }) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedPassport, setSelectedPassport] = useState<PassportType | null>(null);
  const [expiry, setExpiry] = useState<any>(null);
  const [isCollapsed, setIsCollapsed] = useState(false); // Collapsible state
  const api = new Api();

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
  };
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setSelectedPassport(null);
  };

  const handleEditOpen = (passport: PassportType) => {
    setSelectedPassport(passport);
    setEditMode(true);
    setExpiry(dayjs(passport.expiryDate, "DD.MM.YYYY"));
    setOpen(true);
  };

  const handleCloseBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpenError(false);
    setOpenSuccess(false);
  };

  const handleSubmit = async (values: any) => {
    try {
      const data = {
        personId: personID,
        passportNo: values.passportNo,
        expiryDate: dayjs(values.expiryDate).format("DD.MM.YYYY"),
      };

      let response;
      if (editMode && selectedPassport) {
        response = await api.updatePassport(selectedPassport.id, data);
      } else {
        response = await api.addNewPassport(data);
      }

      if (response && response.data) {
        setOpenSuccess(true);
        handleClose();
      }
    } catch (err) {
      console.error(err);
      setOpenError(true);
      handleClose();
    }
  };

  return (
      <Box sx={{ padding: theme.spacing(2) }}>
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleCloseBar}>
          <Alert severity="success" onClose={handleCloseBar}>
            {editMode ? "Passport has been updated successfully" : "New Passport has been added successfully"}
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseBar}>
          <Alert severity="error" onClose={handleCloseBar}>
            Seems like an error occurred while {editMode ? "updating the passport" : "adding a new passport"}!
          </Alert>
        </Snackbar>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="h1" variant="h4" color="primary" gutterBottom sx={{ fontWeight: "bold" }}>
            List of Passports
          </Typography>
          <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
        <Collapse in={!isCollapsed}>
          <Button variant="contained" sx={{ mb: 3 }} color="primary" onClick={handleOpen}>
            Add Passport
          </Button>
          <TableContainer component={Paper} sx={{ borderRadius: 4 }}>
            <Table aria-label="passport table">
              <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
                <TableRow>
                  <TableCell align="center">Passport Number</TableCell>
                  <TableCell align="center">Expiry Date</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(passportList, (i) => (
                    <TableRow key={i.passportNo} sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}>
                      <TableCell align="center">{i.passportNo || "-"}</TableCell>
                      <TableCell align="center">{i.expiryDate || "-"}</TableCell>
                      <TableCell align="center">
                        <Chip label={isActive(i.expiryDate) ? "Active" : "Expired"} color={isActive(i.expiryDate) ? "success" : "error"} />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleEditOpen(i)} color="primary">
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
        <Modal open={open} onClose={handleClose} aria-labelledby="add-passport-modal">
          <Box sx={modalStyle}>
            <Typography id="add-passport-modal" variant="h6" component="h2" mb={2}>
              {editMode ? "Edit Passport" : "Add New Passport"}
            </Typography>
            <Formik
                initialValues={{
                  passportNo: selectedPassport?.passportNo || "",
                  expiryDate: selectedPassport ? dayjs(selectedPassport.expiryDate, "DD.MM.YYYY") : null,
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                  <Form noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                            as={TextField}
                            fullWidth
                            id="passportNo"
                            name="passportNo"
                            label="Passport Number"
                            disabled={editMode}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                              label="Passport Expiry Date"
                              value={expiry}
                              format="DD.MM.YYYY"
                              onChange={(newValue) => {
                                setExpiry(newValue);
                                setFieldValue("expiryDate", newValue);
                              }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="primary" type="submit" sx={{ mr: 1 }}>
                          {editMode ? "Update" : "Save"}
                        </Button>
                        <Button variant="outlined" color="error" onClick={handleClose}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Box>
  );
};
