import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

import {Box, createTheme, Grid, LinearProgress, Paper, styled, ThemeProvider, Typography} from "@mui/material";

import moment from "moment";
import Api, { httpErrorHandler } from '../../../../services/server/api';
import Details from '../../../../components/details';
import {
    formatMalaysianPhoneNumber,
    getAgeByYearOfBirth,
    getAgeCategoryByYearOfBirth, getColourByStatus,
    getGenderByChar,
    statusColorMap
} from '../../../../services/utils/helper';
import { PassportListComp } from '../../../../components/passportListComp';
import { ImmiCardsComp } from '../../../../components/immiCardsComp';
import {QRCodeSVG} from "qrcode.react";
import UploadImage from '../../../../components/Input/upload-image';
import SendEmail from '../../../../components/send-email';
import Attachments from '../../../../components/attachments';
import HealthInformation from '../../../../components/health-information';
import ListingRequests from '../../../../components/listing-requests';

export default function Member() {
    const params= useParams()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const nav= useNavigate()
    const [isLoading, setLoading] = React.useState(true);
    const open = Boolean(anchorEl);
    const [user, setUser] = React.useState < any > ();
    const api = new Api();
const navigate = useNavigate();
    const theme = createTheme();
    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }));

     



    const fetchUser =() => {
        setLoading(true);
        api.getPerson(params.id)
            .then((response) => {
                
                setUser(response.data.data[0]);
                setLoading(false);
                
            })
            .catch(err => {
                setUser([]);
                setLoading(false);
                throw err;
            })
            .catch(httpErrorHandler);
        };
        React.useEffect(() =>{
            fetchUser()
        },[])
    let immiList: any = [];
    let passportList: any = [];
    let userID = "";
    if (user && user.id) {
        userID = user.id;
    }
    if (user && user.immi13) {
        immiList = user.immi13;
    }
    if (user && user.passport) {
        passportList = user.passport;
    }

    if (!user) {
        console.log("url is not valid!");
    }

  return (
    <ThemeProvider theme={theme}>
    <Box   
        sx={{
            m: 2,
            display: "flex",
            flexDirection: "column",
            gap: 3,
            marginBottom:5
        }}
    >
        <Box sx={{flexGrow: 1, paddingLeft: 3,paddingBottom:4 ,background:"#fff"}}  >
            <Grid xs={6} sx={{marginY:3}}  >
            <QRCodeSVG value={process.env.REACT_APP_ENDPOINT+'/user_profile/member/'+userID} />,
            </Grid>
            <Grid container spacing={3} sx={{displayPrint:"flex"}} >
                <Grid sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}} xs={12}>
                    <Typography variant="h6" fontWeight={"700"} sx={{margin: 1}} color="primary">Personal Details</Typography>
                <SendEmail user={user}/>
                </Grid>
                <Grid xs={12} sx={{display:"flex", alignItems:"start", margin:2}}   >
                    <UploadImage user={user} />
                    </Grid>
                <Details title="Full Name" children={`${user ? (user.name ? user.name : "-") : "-"}`}/>
                <Details title="Calling Num"
                         children={`${user ? (user.callingNum ? user.callingNum : "-") : "-"}`}/>
                <Details title="File No." children={`${user ? (user.fileNo ? user.fileNo : "-") : "-"}`}/>
                <Details title="Gender"
                         children={`${user ? (user.gender ? getGenderByChar(user.gender) : "-") : "-"}`}/>
                <Details title="Year of Birth"
                         children={`${user ? (user.yearOfBirth ? user.yearOfBirth : "-") : "-"}`}/>
                <Details title="Age"
                         children={`${getAgeByYearOfBirth(user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0")}`}/>
                <Details title="Age Category"
                         children={`${getAgeCategoryByYearOfBirth(user ? (user.yearOfBirth ? user.yearOfBirth : "0") : "0")}`}/>
                <Details title="document"
                         children={`${user ? (user.typeOfDocument ? user.typeOfDocument : "-") : "-"}`}/>
                <Details title="IMM13 STATUS"
                         children={`${user ? (user.immi13Status ? user.immi13Status : "-") : "-"}`}/>
                <Details title="APPLICANT STATUS"
                         children={`${user ? user.applicationStatus ? user.applicationStatus : "-" : "-"}`}
                         scolour= {getColourByStatus(user ? user.applicationStatus ? user.applicationStatus : "-" : "-")}/>
                <Details title="Notes" children={`${user ? (user.notes ? user.notes : "-") : "-"}`}/>
                <Details title="UN STATUS (UNHCR CARD NO.)"
                         children={`${user ? (user.unStatus ? user.unStatus : "-") : "-"}`}/>
                <Details title="UNHRC CARD EXPIRY DATE"
                         children={`${user ? user.unhrcCardExpiryDate ? user.unhrcCardExpiryDate : "-" : "-"}`}/>
                <Details title="MOBILE NO." children={`${user ? (user.mobileNo ? formatMalaysianPhoneNumber(user?.mobileNo) : "-" ): "-"}`}/>
                <Details title="EMAIL ADDRESS"
                         children={`${user ? (user.emailAddress ? user.emailAddress : "-") : "-"}`}/>
                <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                          color="primary">Address</Typography></Grid>
                <Details title="DISTRICT"
                         children={`${user ? (user.address ? user.address.DISTRICT ? user.address.DISTRICT : "-" : "-") : "-"}`}/>
                <Details title="STATE"
                         children={`${user ? (user.address ? user.address.STATE ? user.address.STATE : '-' : '-') : '-'}`}/>
                <Details title="Detailed Address"
                         children={`${user ? (user.address ? user.address.DETAIL_ADDRESS ? user.address.DETAIL_ADDRESS : '-' : '-') : '-'}`}/>
                <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                          color="primary">Education</Typography></Grid>
                <Details title="Education FIELD" children={`${user ? (user.education ? user.education.FIELD? user.education.FIELD: '-' : '-') : '-'}`}/>
                <Details title="LEVEL" children={`${user ? (user.education ? user.education.LEVEL ? user.education.LEVEL : '-' : '-') : '-'}`}/>
                <Details title="UNIVERSITY" children={`${user ? (user.education ? user.education.UNIVERSITY ? user.education.UNIVERSITY : '-' : '-') : '-'}`}/>

                <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                          color="primary">Employment</Typography></Grid>
                <Details title="DETAILS OF EMPLOYER" children={`${user ? (user.employment ? user.employment.DETAILS_OF_EMPLOYER? user.employment.DETAILS_OF_EMPLOYER: '-' : '-') : '-'}`}/>
                <Details title="POSITION" children={`${user ? (user.employment ? user.employment.POSITION? user.employment.POSITION: '-' : '-') : '-'}`}/>
                <Details title="SALARY" children={`${user ? (user.employment ? user.employment.SALARY? user.employment.SALARY: '-' : '-') : '-'}`}/>
                <Details title="NOTE(S) / REMARK(S)"
                         children={`${user ? (user?.remarks ? user.remarks : '-') : '-'}`}/>
            </Grid>
          

        </Box>
        <PassportListComp
            passportList={passportList}
            personID={userID}
        ></PassportListComp>
        <ImmiCardsComp immiCardList={immiList} personID={userID}></ImmiCardsComp>
{/*
        <ListingRequests user={user}/>
*/}
{/*
        <HealthInformation/>
*/}
        <Attachments user={user}/>
    </Box>
</ThemeProvider>
  )
}
