import {Add, Delete, Edit} from '@mui/icons-material';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import {HealthInfoType} from '../services/utils/types/types';
import {Formik, Form, Field} from 'formik';
import dayjs from "dayjs";
import Api, {httpErrorHandler} from "../services/server/api";

interface HealthInformationProps {
    healthInfoList: HealthInfoType[];
    personId: string;
}

const api = new Api();

const HealthInformation: React.FC<HealthInformationProps> = ({healthInfoList, personId}) => {
    const [healthInfo, setHealthInfo] = useState<HealthInfoType[]>(healthInfoList);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [currentRecord, setCurrentRecord] = useState<HealthInfoType>({
        hospital: '',
        treatment: '',
        status: '',
        diagnose: ''
    });
    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        message: string,
        severity: 'success' | 'error'
    }>({open: false, message: '', severity: 'success'});

    useEffect(() => {
        setHealthInfo(healthInfoList);
    }, [healthInfoList]);

    const handleApiResponse = (response: any, successMessage: string) => {
        if (response.status === 200 || response.status === 201) {
            setSnackbar({open: true, message: successMessage, severity: 'success'});
            window.location.reload();
        } else {
            setSnackbar({open: true, message: 'Operation failed. Please try again.', severity: 'error'});
        }
    };

    const handleAddOrEdit = async (values: HealthInfoType) => {
        const method = currentRecord.id ? 'PUT' : 'POST';

        let response;
        try {
            if (method === "POST") {
                response = await api.addHealthRequest(values);
            } else if (method === "PUT") {
                response = await api.updateHealthRequest(currentRecord.id, values);
            }

            handleApiResponse(response, method === 'POST' ? 'Added successfully' : 'Updated successfully');

        } catch (err) {
            console.log(err);
            httpErrorHandler(err);
        }
        setIsDialogOpen(false);
        setCurrentRecord({hospital: '', treatment: '', status: '', diagnose: ''});
    };

    const handleDelete = async (id: string | undefined) => {
        if (!id) return;
        const response = await api.deleteHealthRequest(id);
        handleApiResponse(response, 'Deleted successfully');
    };

    const openEditDialog = (record: HealthInfoType) => {
        setCurrentRecord(record);
        setIsDialogOpen(true);
    };

    return (
        <Box>
            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{flexGrow: 1, m: 2, fontWeight: "bold"}}
            >
                List of Health Information
            </Typography>
            <Button
                variant="contained"
                sx={{m: 2, fontWeight: "bold"}}
                color="secondary"
                onClick={() => {
                    setCurrentRecord({hospital: '', treatment: '', status: '', diagnose: ''});
                    setIsDialogOpen(true);
                }}
            >
                <Add/> Add Health Information
            </Button>
            <TableContainer component={Paper}>
                <Table aria-label="health information table" sx={{margin: 2, width: "90%"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">HOSPITAL</TableCell>
                            <TableCell align="center">TREATMENT</TableCell>
                            <TableCell align="center">STATUS</TableCell>
                            <TableCell align="center">DIAGNOSE</TableCell>
                            <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {healthInfo.map((record) => (
                            <TableRow key={record.id || Math.random()}
                                      sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                <TableCell align="center">{record.hospital}</TableCell>
                                <TableCell align="center">{record.treatment}</TableCell>
                                <TableCell align="center">{record.status}</TableCell>
                                <TableCell align="center">{record.diagnose}</TableCell>
                                <TableCell align="center">
                                    <Button color="secondary" onClick={() => openEditDialog(record)}><Edit/></Button>
                                    <Button color="error" onClick={() => handleDelete(record.id)}><Delete/></Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>{currentRecord.id ? 'Edit Health Information' : 'Add Health Information'}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={currentRecord}
                        onSubmit={(values) => handleAddOrEdit({...values, personId})}
                    >
                        {({handleChange, values}) => (
                            <Form>
                                <TextField
                                    margin="dense"
                                    label="Hospital"
                                    fullWidth
                                    name="hospital"
                                    value={values.hospital}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Treatment"
                                    fullWidth
                                    name="treatment"
                                    value={values.treatment}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Status"
                                    fullWidth
                                    name="status"
                                    value={values.status}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Diagnose"
                                    fullWidth
                                    name="diagnose"
                                    value={values.diagnose}
                                    onChange={handleChange}
                                />
                                <DialogActions>
                                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                                    <Button type="submit">{currentRecord.id ? 'Update' : 'Add'}</Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({...snackbar, open: false})}
            >
                <Alert onClose={() => setSnackbar({...snackbar, open: false})} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default HealthInformation;
