import {Avatar, Box, createTheme, Paper, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";
import SimpleTable from "../../components/SimpleTable";

interface ProgrammeStatisticsProps {

}

const theme = createTheme();

export const ProgrammeStatistics: React.FC<ProgrammeStatisticsProps> = (props) => {

    return (
        <ThemeProvider theme={theme}>
            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{ my: 4,
                    mx: 4 }}
            >
                Programme Statistic
            </Typography>
            <Box
                sx={{
                    my: 4,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 200px)'
                }}
            >
                <Paper sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} elevation={2} >
                    <Typography
                        component="h1"
                        variant="h4"
                        color="inherit"
                        noWrap
                        sx={{ my: 4,
                            mx: 4 }}
                    >
                        Coming Soon!!
                    </Typography>
                </Paper>
            </Box>
        </ThemeProvider>
    );
};