import * as React from "react";
import { CreateUser } from "../../services/utils/types/types";
import Api, { httpErrorHandler } from "../../services/server/api";
import { createTheme } from "@mui/material";
import CreatePerson from "../../components/create-person";
import moment from "moment";
import { useNavigate } from "react-router";
import { Alert, Snackbar } from "@mui/material";
import {useState} from "react";

interface NewApplicationProps {}

const theme = createTheme({
  palette: {
    primary: {
      main: "#8a009b",
    },
  },
});

export const NewApplication: React.FC<NewApplicationProps> = (props) => {
  const [message, setMessage] = useState<{ type: "success" | "error"; text: string } | null>(null);
  const initialValues: CreateUser = {
    name: "",
    gender: "",
    yearOfBirth: "",
    typeOfDocument: "",
    emailAddress: "",
    callingNum: "",
    fileNo: "",
    serielNo: "",
    immi13Status: "",
    applicationStatus: "",
    notes: "",
    education: { LEVEL: "", FIELD: "", UNIVERSITY: "" },
    unStatus: "",
    unhrcCardExpiryDate: "",
    mobileNo: "",
    address: { DISTRICT: "", STATE: "", DETAIL_ADDRESS: "" },
    employment: { SALARY: "", POSITION: "", DETAILS_OF_EMPLOYER: "" },
    typeOfPass: "",
    relationship: "",
    remarks: "",
    dateOfBirth: "",
  };

  const api = new Api();
  const navigate = useNavigate();

  const onSubmit = async (values: CreateUser) => {
    try {
      let dob = moment(new Date(values.dateOfBirth)).format("DD/MM/YYYY");
      values.yearOfBirth = moment(new Date(values.dateOfBirth)).format("YYYY");
      values.dateOfBirth = dob;
      await api.addNewApplication(values);
      setMessage({ type: "success", text: "Application created successfully." });
      navigate(-1);
    } catch (error) {
      httpErrorHandler(error);
      setMessage({ type: "error", text: "Failed to create application. Please try again." });
    }
  };

  const handleClose = () => {
    setMessage(null);
  };

  return (
      <>
        <CreatePerson initialValues={initialValues} onSubmit={onSubmit} label="New Application" />
        {message && (
            <Snackbar open={true} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={message.type}>
                {message.text}
              </Alert>
            </Snackbar>
        )}
      </>
  );
};
