export const LOGIN = 'login';
export const SIGN_IN = 'sign_in';
export const ADMINDASHBOARD = 'admin_dashboard';
export const VIEW_APPLICATION = 'view_application';
export const PRINT_APPLICATION = 'print_application';
export const NEW_APPLICATION = 'new_application';
export const REPORTS = 'reports';
export const PROGRAMME_STATISTICS = 'programme_statistics';
export const USERPROFILE = 'user_profile';
export const USERPROFILEID = `${USERPROFILE}/:id`;
export const USERPROFILEADDMEMBERID = `${USERPROFILE}/add_member`;
export const USERPROFILEMEMBERID= `${USERPROFILE}/member/:id`;
export const PRINTQRCODE= 'printqrcode';
export const PERSONALDETAILS = `${USERPROFILE}/personal_details`;
export const SPOUSEDETAILS = `${USERPROFILE}/spouse_details`;
export const FAMILYMEMBERS = `${USERPROFILE}/family_members`;
export const ACCOUNTSETTINGS = `${USERPROFILE}/account_settings`;