import { Box, Button, createTheme, FormLabel, Grid, Paper, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";
import {map} from "lodash";
import {LabelWithDetails} from "../../../../components/LabelWithDetails";
import Divider from "@mui/material/Divider";
import {
    formatMalaysianPhoneNumber,
    getAgeByYearOfBirth,
    getAgeCategoryByYearOfBirth, getColourByStatus,
    getFamilyMembersDetails,
    getGenderByChar
} from "../../../../services/utils/helper";
import {PassportListComp} from "../../../../components/passportListComp";
import {ImmiCardsComp} from "../../../../components/immiCardsComp";
import {applicationType, userType} from "../../../../services/utils/types/types";
import {createSearchParams, Link} from "react-router-dom";
import {useNavigate} from "react-router";
import {USERPROFILE, USERPROFILEADDMEMBERID} from "../../../../constants/routes";
import Details from "../../../../components/details";
import { dom } from "@fortawesome/fontawesome-svg-core";
import SendEmail from "../../../../components/send-email";
import UploadImage from "../../../../components/Input/upload-image";
import Attachments from "../../../../components/attachments";
import { Edit, Person } from "@mui/icons-material";
import HealthInformation from "../../../../components/health-information";
import ListingRequests from "../../../../components/listing-requests";


let appId="";

interface FamilyMembersProps {
application?:applicationType;
}

const theme = createTheme();


export const FamilyMembers: React.FC<FamilyMembersProps> = (props) => {
    let navigate = useNavigate();

    const queryParameters = new URLSearchParams(window.location.search)
     const id = queryParameters.get("id");
    appId = id?id:"";
    let userList: userType[], user: userType | undefined = {};
    let newArr;
    if(props&&props.application&&props.application.person&&props.application.person){
        userList = props.application.person;
        userList = userList.filter(person =>person.relationship?(person.relationship.toLowerCase()!=="spouse"&&person.relationship.toLowerCase()!=="main applicant"):false);
         newArr = userList.map((element:userType, index) => {
            const item = {
                dom:element,
                immi13: element.immi13,
                passport:element.passport,
                health: element.health,
                request:element.request,
                userID: element.id,
            }
            
            return item;
        } );

    }
    if(!user){
        console.log('url is not valid!')
    }


    function goToAddMember(appId: string) {
        navigate({
            pathname: '/' + USERPROFILEADDMEMBERID,
            search: createSearchParams({
                id: appId
            }).toString()
        });
    }

    // console.log("user",newArr?.[0].dom);
    
    return (
        <ThemeProvider theme={theme}>
            <Button variant="text" sx={{paddingLeft:2}} onClick={() => {
                goToAddMember(appId);
            }}>Add New Family Member</Button>
            {map(newArr, (item, index) => {
                return <div>
                 <FormLabel sx={{m:2, paddingLeft:1}}>Family Member {index +1}</FormLabel>

                 <Box
                    sx={{
                        m: 2,
                        display:"flex",
                        flexDirection:"column",
                        gap:2
                    }}
                >
                     {/* <div style={{display: 'grid',
                        gridTemplateColumns: 'auto auto auto',}}> 
                        {map(item.doms, (i) => (
                            <LabelWithDetails title={i.title.toUpperCase()}>{i.detail}</LabelWithDetails>
                        ))} 
                     </div>  */}
                     <Box  sx={{ flexGrow: 1, paddingLeft:2}}>
                         <Grid container spacing={2}>
                         <Grid xs={12}>
                                 <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                     <Typography variant="h6" fontWeight={"700"} sx={{margin: 1}} color="primary">Personal Details</Typography>
                                     <Box display={"flex"} gap={1} alignItems={"center"}>

                                         <Link to={`/user_profile/${item.userID}`}>
                                             <Button variant="contained" sx={{gap:1}}><Edit/>edit</Button>
                                         </Link>
                                         <Link to={`member/${item.userID}`}>
                                             <Button variant="contained" sx={{gap:1}}><Person/> MEMBER</Button>
                                         </Link>
                                         <SendEmail user={item.dom}/>
                                     </Box>
                                 </Box>
                             </Grid>
                             <Grid xs={12} sx={{display:"flex", alignItems:"start", margin:2}}   >
                    <UploadImage  user={item.dom} />
                    </Grid>
                             <Details title="RELATIONSHIP TYPE"
                                      children={`${item.dom ? (item.dom.relationship ? item.dom.relationship : "-") : "-"}`}/>

                             <Details title="Full Name" children={`${item.dom ? (item.dom.name ? item.dom.name : "-") : "-"}`}/>
                             <Details title="Calling Num"
                                      children={`${item.dom ? (item.dom.callingNum ? item.dom.callingNum : "-") : "-"}`}/>
                             <Details title="File No." children={`${item.dom ? (item.dom.fileNo ? item.dom.fileNo : "-") : "-"}`}/>
                             <Details title="Gender"
                                      children={`${item.dom ? (item.dom.gender ? getGenderByChar(item.dom.gender) : "-") : "-"}`}/>
                             <Details title="Year of Birth"
                                      children={`${item.dom ? (item.dom.yearOfBirth ? item.dom.yearOfBirth : "-") : "-"}`}/>
                             <Details title="Age"
                                      children={`${getAgeByYearOfBirth(item.dom ? (item.dom.yearOfBirth ? item.dom.yearOfBirth : "0") : "0")}`}/>
                             <Details title="Age Category"
                                      children={`${getAgeCategoryByYearOfBirth(item.dom ? (item.dom.yearOfBirth ? item.dom.yearOfBirth : "0") : "0")}`}/>
                             <Details title="document"
                                      children={`${item.dom ? (item.dom.typeOfDocument ? item.dom.typeOfDocument : "-") : "-"}`}/>
                             <Details title="IMM13 STATUS"
                                      children={`${item.dom ? (item.dom.immi13Status ? item.dom.immi13Status : "-") : "-"}`}/>
                             <Details title="APPLICANT STATUS"
                                      children={`${item.dom ? item.dom.applicationStatus ? item.dom.applicationStatus : "-" : "-"}`} scolour={getColourByStatus(item.dom ? item.dom.applicationStatus ? item.dom.applicationStatus:"-":"-")}/>
                             <Details title="Notes" children={`${item.dom ? (item.dom.notes ? item.dom.notes : "-") : "-"}`}/>
                             <Details title="UN STATUS (UNHCR CARD NO.)"
                                      children={`${item.dom ? (item.dom.unStatus ? item.dom.unStatus : "-") : "-"}`}/>
                             <Details title="UNHRC CARD EXPIRY DATE"
                                      children={`${item.dom ? item.dom.unhrcCardExpiryDate ? item.dom.unhrcCardExpiryDate : "-" : "-"}`}/>
                             <Details title="MOBILE NO." children={`${item.dom ? (item.dom.mobileNo ? formatMalaysianPhoneNumber(item.dom.mobileNo) : "-") : "-"}`}/>
                             <Details title="EMAIL ADDRESS"
                                      children={`${item.dom ? (item.dom.emailAddress ? item.dom.emailAddress : "-") : "-"}`}/>
                             <Details title="NOTE(S) / REMARK(S)"
                                      children={`${item.dom ? (item.dom?.remarks ? item.dom.remarks : '-') : '-'}`}/>

                             <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                       color="primary">Address</Typography></Grid>
                             <Details title="DISTRICT"
                                      children={`${item.dom ? (item.dom.address ? item.dom.address.DISTRICT ? item.dom.address.DISTRICT : "-" : "-") : "-"}`}/>
                             <Details title="STATE"
                                      children={`${item.dom ? (item.dom.address ? item.dom.address.STATE ? item.dom.address.STATE : '-' : '-') : '-'}`}/>
                             <Details title="Detailed Address"
                                      children={`${item.dom ? (item.dom.address ? item.dom.address.DETAIL_ADDRESS ? item.dom.address.DETAIL_ADDRESS : '-' : '-') : '-'}`}/>
                             <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                       color="primary">Education</Typography></Grid>
                             <Details title="Education FIELD" children={`${item.dom ? (item.dom.education ? item.dom.education.FIELD? item.dom.education.FIELD: '-' : '-') : '-'}`}/>
                             <Details title="LEVEL" children={`${item.dom ? (item.dom.education ? item.dom.education.LEVEL ? item.dom.education.LEVEL : '-' : '-') : '-'}`}/>
                             <Details title="UNIVERSITY" children={`${item.dom ? (item.dom.education ? item.dom.education.UNIVERSITY ? item.dom.education.UNIVERSITY : '-' : '-') : '-'}`}/>

                             <Grid xs={12}><Typography variant="h6" fontWeight={"700"} sx={{margin: 1, marginTop: 4}}
                                                       color="primary">Employment</Typography></Grid>
                             <Details title="DETAILS OF EMPLOYER" children={`${item.dom ? (item.dom.employment ? item.dom.employment.DETAILS_OF_EMPLOYER? item.dom.employment.DETAILS_OF_EMPLOYER: '-' : '-') : '-'}`}/>
                             <Details title="POSITION" children={`${item.dom ? (item.dom.employment ? item.dom.employment.POSITION? item.dom.employment.POSITION: '-' : '-') : '-'}`}/>
                             <Details title="SALARY" children={`${item.dom ? (item.dom.employment ? item.dom.employment.SALARY? item.dom.employment.SALARY: '-' : '-') : '-'}`}/>
                         </Grid>
                     </Box>

                 {/*List of passports*/}

                 <PassportListComp passportList={item.passport?item.passport:[]} personID={item.userID?item.userID:""}></PassportListComp>
                 

                 {/*List of IMMI13 cards*/}
                 <ImmiCardsComp immiCardList={item.immi13?item.immi13:[]} personID={item.userID?item.userID:""}></ImmiCardsComp>
                 <Divider variant="fullWidth" sx={{ borderBottomWidth: 5 }}/>
                 <ListingRequests requestsList={item.request?item.request:[]}
                                  personId={item.userID?item.userID:""}/>
                     <HealthInformation healthInfoList ={item.health?item.health:[]}
                                        personId={item.userID?item.userID:""}/>
                 <Attachments user={item.dom}/>
                </Box>

             </div>
            })}


        </ThemeProvider>
    );
};