import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useField } from 'formik';
import { Typography } from '@mui/material';

interface TextSelectProps {
  name: string;
  labelInput: string;
  option: string[];
}

export default function TextSelect({ name, labelInput, option }: TextSelectProps) {
  const [field, meta] = useField(name);

  return (
      <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
        <InputLabel id={`${name}-label`}>{labelInput}</InputLabel>
        <Select
            fullWidth
            label={labelInput}
            labelId={`${name}-label`}
            {...field} // This handles `value` and `onChange` via Formik
        >
          {option.map((el, index) => (
              <MenuItem key={index} value={el}>
                {el}
              </MenuItem>
          ))}
        </Select>
        {meta.touched && meta.error ? (
            <Typography variant="subtitle2" color="error">
              {meta.error}
            </Typography>
        ) : null}
      </FormControl>
  );
}
