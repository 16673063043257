import * as React from 'react';
import Typography from "@mui/material/Typography";

interface RowDetailWithBorderProps {
    title: string;
    children?: React.ReactNode;
}

export const RowDetailWithBorder: React.FC<RowDetailWithBorderProps> = ({ title, children }) => {
    return (
        <div style={{display: 'flex', padding: '10px 5px', borderBottom: '1px solid #c1c0c0', width: '100%', justifyContent: 'space-between'}}>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
                sx={{ fontWeight: '600' }}
            >
                {title}
            </Typography>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
            >
                {children}
            </Typography>
        </div>
    );
};
