import {
    Box, Button,
    Icon,
    IconButton, InputBase, LinearProgress, Menu, MenuItem,
    styled,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import React, {SetStateAction, useEffect, useState} from "react";
import {
    faEllipsisV,
    faEye,
    faEyeLowVision,
    faEyeSlash,
    faFolderMinus,
    faFolderOpen, faFrownOpen
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {faker} from "@faker-js/faker";
import {createSearchParams, useNavigate} from "react-router-dom";
import {PRINT_APPLICATION, USERPROFILE} from "../constants/routes";
import SearchIcon from '@mui/icons-material/Search';
import * as people from '../services/utils/people.json';
import {getGenderByChar} from "../services/utils/helper";
import Api, {httpErrorHandler} from "../services/server/api";
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": {"& th": {paddingLeft: 0, paddingRight: 0}},
    },
    "& tbody": {
        "& tr": {"& td": {paddingLeft: 0, textTransform: "capitalize"}},
    },
}));

const SimpleTable = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [data, setData] = useState([{}]);
    const [isLoading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0); // To manage total record count from backend

    const navigate = useNavigate(); // Moved useNavigate here, inside the component

    const handelDelete= (params: any) => {
        console.log(params);

        setLoading(true);
        const api = new Api();
        api.deletePerson(params)
            .then((response : any) => {
                console.log("delete");

                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);

            });
    }

    const goToProfile = (appId: string) => {
        navigate({
            pathname: '/' + USERPROFILE,
            search: createSearchParams({
                id: appId
            }).toString()
        });
    }

    const fetchUser = (params: any) => {
        setLoading(true);
        const api = new Api();
        api
            .getUserList(params)
            .then((response) => {
                const { data, totalCount } = response.data; // Assuming backend returns `data` and `totalCount`
                setData(data);
                setTotalRecords(totalCount); // Store total records
                setLoading(false);
            })
            .catch((err) => {
                setData([]);
                setLoading(false);
                throw err;
            })
            .catch(httpErrorHandler);
    };

    useEffect(() => {
        const offset = page * rowsPerPage;
        fetchUser({ perPage: rowsPerPage, offset: offset });
    }, [page, rowsPerPage]);

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage); // This will trigger the useEffect and update the table data
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10)); // Update rows per page
        setPage(0); // Reset to the first page whenever rows per page change
    };

    return (
        <Box width="100%" overflow="auto">
            {isLoading && <LinearProgress color="secondary" />}

            <InputBase
                sx={{ ml: 1, alignItems: 'center' }}
                placeholder="Search applicants"
                onChange={(e) => fetchUser({ search: e.target.value, perPage: rowsPerPage, offset: page * rowsPerPage })}
                inputProps={{ 'aria-label': 'Search Applicants' }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>

            <StyledTable>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">FULL NAME</TableCell>
                        <TableCell align="center">CALLING NUM</TableCell>
                        <TableCell align="center">GENDER</TableCell>
                        <TableCell align="center">TYPE OF DOC</TableCell>
                        <TableCell align="center">STATUS</TableCell>
                        <TableCell align="center">ACTION</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((subscriber: any, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">{subscriber.name}</TableCell>
                            <TableCell align="center">{subscriber.callingNum}</TableCell>
                            <TableCell align="center">{getGenderByChar(subscriber.gender)}</TableCell>
                            <TableCell align="center">{subscriber.typeOfDocument}</TableCell>
                            <TableCell align="center">{subscriber.applicationStatus}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => goToProfile(subscriber.applicationId)}>
                                    <FontAwesomeIcon icon={faFolderOpen as IconProp} />
                                </IconButton>
                                <IconButton onClick={() => handelDelete(subscriber)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>

            <TablePagination
                component="div"
                page={page}
                rowsPerPage={rowsPerPage}
                count={totalRecords} // This is the total record count from the backend
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
            />
        </Box>
    );
};

export default SimpleTable;
