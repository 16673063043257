

import { Box, TextField, Typography } from '@mui/material'
import { useField } from 'formik';
import React from 'react'

export default function TextInput({id="", label="", name="", type=""}) {
  const [field, meta] = useField(name);

  return (
    <Box>

    <TextField error={meta.touched && !! meta.error} fullWidth id={id =name} label={label}   {...field} variant="outlined"   type={type} />
    <Typography  variant='subtitle2'  color={"error"}>
    {meta.touched && meta.error ? meta.error: ""}
    </Typography>
    </Box>
  )
}
