import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Snackbar,
    Alert,
    Typography,
    styled,
} from '@mui/material';
import React, { useState } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Api from '../../services/server/api';
import { useParams } from 'react-router';

export default function UploadImage(user : any) {
    // const param = useParams();
    const [open, setOpen] = useState(false);
    const [img, setImage] = useState<any>(null);
    const [file, setFile] = useState<any>();

    const [successMessage, setSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const api = new Api();

    const handleClick = async () => {
        const data = new FormData();
        data.append('file', file);
        data.append('title', 'image');
        data.append('description', 'image');
        data.append('isMainPhoto', 'true');

        try {
            await api.uploadFile(user?.user?.id, data);
            setSuccessMessage(true);  // Show success message
            setOpen(false);
        } catch (error: any) {
            setErrorMessage(error?.response?.data?.message || 'Error uploading image'); // Show error message
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => {
        setSuccessMessage(false);
        setErrorMessage(null);
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    const onImageChange = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setFile(event.target.files[0]);
        }
    };

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                <Avatar
                    src={user?.user?.mainPhoto || img}
                    alt="Profile"
                    sx={{
                        width: 120,
                        height: 120,
                        borderRadius: '50%',
                        objectFit: 'cover',
                        mb: 2,
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Added subtle shadow for elegance
                    }}
                />
                <Button
                    onClick={handleClickOpen}
                    variant="contained"
                    startIcon={<CameraAltIcon />}
                    sx={{
                        textTransform: 'none',
                        backgroundColor: '#3f51b5',
                        '&:hover': {
                            backgroundColor: '#303f9f', // Slightly darker on hover
                        },
                    }}
                >
                    Change Profile Photo
                </Button>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        padding: '20px',
                        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)', // Adding shadow for the dialog
                    },
                }}
            >
                <DialogContent sx={{ textAlign: 'center', padding: '30px 20px' }}>
                    <Typography variant="h6" gutterBottom sx={{ color: '#333' }}>
                        Upload a New Photo
                    </Typography>

                    <Button
                        component="label"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#3f51b5',
                            '&:hover': {
                                backgroundColor: '#303f9f',
                            },
                            mb: 3,
                        }}
                    >
                        Select Photo
                        <VisuallyHiddenInput type="file" onChange={onImageChange}  />
                    </Button>

                    {img && (
                        <Box mt={3}>
                            <img
                                src={img}
                                alt="Preview"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Added shadow for preview image
                                }}
                            />
                        </Box>
                    )}
                </DialogContent>

                <DialogActions sx={{ justifyContent: 'space-around', padding: '10px 24px' }}>
                    <Button
                        onClick={handleClose}
                        sx={{
                            color: '#f50057', // Changed Cancel button to a soft red
                            textTransform: 'none',
                            '&:hover': {
                                backgroundColor: 'rgba(245, 0, 87, 0.1)', // Add light hover effect
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleClick}
                        variant="contained"
                        color="primary"
                        sx={{
                            textTransform: 'none',
                            backgroundColor: '#3f51b5',
                            '&:hover': {
                                backgroundColor: '#303f9f',
                            },
                        }}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for success and error messages */}
            <Snackbar
                open={successMessage}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Image uploaded successfully!
                </Alert>
            </Snackbar>

            {errorMessage && (
                <Snackbar open={!!errorMessage} autoHideDuration={5000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}
