import * as React from "react";
import { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  createTheme,
  Grid,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  TableCell,
  TextField,
} from "@mui/material";
import { map } from "lodash";
import { imm13Status, isActive } from "../services/utils/helper";
import { ImmiCardsType } from "../services/utils/types/types";
import Api from "../services/server/api";
import dayjs from "dayjs"; // Import Day.js instead of moment
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextSelect from "./Input/text-select";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";

interface ImmiCardsComp {
  immiCardList: Array<ImmiCardsType>;
  personID: string;
}

const theme = createTheme();
const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  cardNumber: Yup.string().required("Card Number is required"),
  status: Yup.string().required("Card status is required")
});

export const ImmiCardsComp: React.FC<ImmiCardsComp> = ({
                                                         immiCardList,
                                                         personID,
                                                       }) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [expiry, setExpiry] = useState<any>(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedCard, setSelectedCard] = useState<ImmiCardsType | null>(null);

  const handleOpen = () => {
    setOpen(true);
    setEditMode(false);
  };
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setSelectedCard(null);
  };

  const handleEditOpen = (card: ImmiCardsType) => {
    console.log("Editing Card:", card); // Debugging line
    setSelectedCard(card);
    setEditMode(true);
    const date = dayjs(card.expiryDate, "DD.MM.YYYY"); // Use Day.js here
    setExpiry(date);
    setOpen(true);
  };

  const handleCloseBar = (
      event?: React.SyntheticEvent | Event,
      reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
    setOpenSuccess(false);
  };

  const api = new Api();

  const handleSubmit = async (values: any) => {
    try {
      const data = {
        personId: personID,
        cardNumber: values.cardNumber,
        status: values.status,
        typeOfVisa: values.typeOfVisa,
        expiryDate: dayjs(expiry).format("DD.MM.YYYY"), // Use Day.js for formatting
        isOriginal: editMode && selectedCard ? selectedCard.isOriginal : false,
      };

      let response;
      if (editMode && selectedCard) {
        // If in edit mode, update the card

                response = await api.updateImmiCard(selectedCard.id, data);

      } else {
        // Else, add a new card
        response = await api.addNewCards(data);
      }

      if (response && response.data) {
        setOpenSuccess(true);
        handleClose();
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setOpenError(true);
      handleClose();
    }
  };

  return (
      <Box sx={{ padding: theme.spacing(2) }}>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openSuccess}
            autoHideDuration={6000}
            onClose={handleCloseBar}
        >
          <Alert severity="success" sx={{ width: "100%" }} onClose={handleCloseBar}>
            {editMode ? "Card has been updated successfully" : "New Passport Has been added successfully"}
          </Alert>
        </Snackbar>
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={openError}
            autoHideDuration={6000}
            onClose={handleCloseBar}
        >
          <Alert severity="error" sx={{ width: "100%" }} onClose={handleCloseBar}>
            Seems like an error happened while {editMode ? "updating the card" : "adding new passport"}!
          </Alert>
        </Snackbar>
        <Typography
            component="h1"
            variant="h4"
            color="primary"
            gutterBottom
            sx={{ fontWeight: "bold" }}
        >
          List of IMMI13 Cards
        </Typography>
        <Button
            variant="contained"
            sx={{ mb: 3 }}
            color="primary"
            onClick={handleOpen}
        >
          Add IMMI13 Card
        </Button>

        <TableContainer component={Paper} sx={{ borderRadius: 4 }}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
              <TableRow>
                <TableCell align="center">CARD NUMBER</TableCell>
                <TableCell align="center">EXPIRY DATE</TableCell>
                <TableCell align="center">STATUS</TableCell>
                <TableCell align="center">Type of Visa</TableCell>
                <TableCell align="center">EXPIRY</TableCell>
                <TableCell align="center">ACTIONS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(immiCardList, (i) => (
                  <TableRow key={i.cardNumber} sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}>
                    <TableCell align="center">{i.cardNumber || "-"}</TableCell>
                    <TableCell align="center">{i.expiryDate || "-"}</TableCell>
                    <TableCell align="center">
                      <Chip
                          label={i.status? i.status.toUpperCase() : "-"}
                          color="secondary"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                          label={i.isOriginal === "true" ? "ORIGINAL" : i.typeOfVisa? i.typeOfVisa.toUpperCase() : "-"}
                          color={i.isOriginal === "true" ? "info" : "secondary"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Chip
                          label={isActive(i.expiryDate) ? "ACTIVE" : "EXPIRED"}
                          color={isActive(i.expiryDate) ? "success" : "error"}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleEditOpen(i)} color="primary">
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal open={open} onClose={handleClose} aria-labelledby="add-new-card-modal">
          <Box sx={modalStyle}>
            <Typography id="add-new-card-modal" variant="h6" component="h2" mb={2}>
              {editMode ? "Edit Card" : "Add New Card"}
            </Typography>
            <Formik
                initialValues={{
                  cardNumber: selectedCard?.cardNumber || "",
                  status: selectedCard?.status || "",
                  typeOfVisa: selectedCard?.typeOfVisa || "",
                  expiryDate: selectedCard ? dayjs(selectedCard.expiryDate, "DD.MM.YYYY") : null, // Use Day.js here
                }}
                enableReinitialize // This will ensure the form updates when `selectedCard` changes
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                  <Form noValidate autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                            as={TextField}
                            required
                            fullWidth
                            id="cardNumber"
                            name="cardNumber"
                            label="Card Number"
                          />
                      </Grid>
                      <Grid item xs={12}>
                        <TextSelect
                            labelInput="Card Status"
                            name="status"
                            option={imm13Status}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                            as={TextField}
                            required
                            fullWidth
                            id="typeOfVisa"
                            name="typeOfVisa"
                            label="Type Of Visa"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                              label="Visa Expiry Date"
                              value={expiry}
                              format="DD.MM.YYYY"
                              onChange={(newValue) => {
                                setExpiry(newValue);
                                setFieldValue("expiryDate", newValue);
                              }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            sx={{ mr: 1 }}
                        >
                          {editMode ? "Update" : "Save"}
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </Box>
  );
};
