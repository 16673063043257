import {Avatar, Box, createTheme, Paper, ThemeProvider} from "@mui/material";
import * as React from "react";
interface userType {
    name?: string,
    callingNum?: string,
    mobileNo?: string,
    emailAddress?: string,
    applicationStatus?: string,
    relationship?:string
}

interface applicationType {
    name?: string,
    callingNum?: string,
    mobileNo?: string,
    emailAddress?: string,
    applicationStatus?: string,
    person: userType[] | null,
}
interface AccountSettingsProps {
    application?:applicationType;
}

const theme = createTheme();

export const AccountSettings: React.FC<AccountSettingsProps> = (props) => {

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'calc(100vh - 200px)'
                }}
            >
                <Paper sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} elevation={2} >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    </Avatar>
                </Paper>
            </Box>

        </ThemeProvider>
    );
};