import { Grid, Typography } from '@mui/material';
import React from 'react'

export default function Details({title, children, scolour, sx}:{title:string, children:string, scolour?:string, sx?: any}) {

  return (

    <Grid item xs={6} lg={4} >
        <Typography
                component="h1"
                variant="inherit"
                color="h6"
                sx={{ fontWeight: '700', ...sx }}
            >
                {title}
            </Typography>
            <Typography
                component="h1"
                variant="subtitle2"
                color="inherit"
                style={{backgroundColor: scolour, ...sx}}
            >
                {children}
            </Typography>
        </Grid>

  )
}
