import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Add, Delete } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Formik, Form } from 'formik';
import Api, { httpErrorHandler } from '../services/server/api';
import {GeneralRequestType} from "../services/utils/types/types";



interface ListingRequestsProps {
    requestsList: GeneralRequestType[];
    personId: string;
}

const api = new Api();

const ListingRequests: React.FC<ListingRequestsProps> = ({ requestsList, personId }) => {
    const [requests, setRequests] = useState<GeneralRequestType[]>(requestsList);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [currentRecord, setCurrentRecord] = useState<GeneralRequestType>({
        title: '',
        description: '',
        status: '',
        type: '',
        completionDate: ''
    });
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error';
    }>({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        setRequests(requestsList);
    }, [requestsList]);

    const handleApiResponse = (response: any, successMessage: string) => {
        if (response.status === 200 || response.status === 201) {
            setSnackbar({ open: true, message: successMessage, severity: 'success' });
            window.location.reload();
        } else {
            setSnackbar({ open: true, message: 'Operation failed. Please try again.', severity: 'error' });
        }
    };

    const handleAddOrEdit = async (values: GeneralRequestType) => {
        const method = currentRecord.id ? 'PUT' : 'POST';

        let response;
        try {
            if (method === 'POST') {
                response = await api.addGeneralRequest(values);
            } else if (method === 'PUT') {
                response = await api.updateGeneralRequest(currentRecord.id as string, values);
            }

            handleApiResponse(response, method === 'POST' ? 'Added successfully' : 'Updated successfully');
        } catch (err) {
            console.log(err);
            httpErrorHandler(err);
        }
        setIsDialogOpen(false);
        setCurrentRecord({ title: '', description: '', status: '', type: '', completionDate: '' });
    };

    const handleDelete = async (id: string | undefined) => {
        if (!id) return;
        try {
            const response = await api.deleteGeneralRequest(id);
            handleApiResponse(response, 'Deleted successfully');
        } catch (err) {
            console.log(err);
            httpErrorHandler(err);
        }
    };

    const openEditDialog = (record: GeneralRequestType) => {
        setCurrentRecord(record);
        setIsDialogOpen(true);
    };

    return (
        <Box>
            <Typography component="h1" variant="h5" color="inherit" noWrap sx={{ flexGrow: 1, m: 2, fontWeight: 'bold' }}>
                Generic Requests
            </Typography>
            <Button
                variant="contained"
                sx={{ m: 2, fontWeight: 'bold' }}
                color="secondary"
                onClick={() => {
                    setCurrentRecord({ title: '', description: '', status: '', type: '', completionDate: '' });
                    setIsDialogOpen(true);
                }}
            >
                <Add /> Place Orders
            </Button>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ margin: 2, width: '90%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">TITLE</TableCell>
                            <TableCell align="center">DESCRIPTION</TableCell>
                            <TableCell align="center">TYPE</TableCell>
                            <TableCell align="center">STATUS</TableCell>
                            <TableCell align="center">COMPLETION DATE</TableCell>
                            <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map((record) => (
                            <TableRow key={record.id}>
                                <TableCell align="center">{record.title}</TableCell>
                                <TableCell align="center">{record.description}</TableCell>
                                <TableCell align="center">{record.type}</TableCell>
                                <TableCell align="center">{record.status}</TableCell>
                                <TableCell align="center">{record.completionDate}</TableCell>
                                <TableCell align="center">
                                    <Button color="secondary" onClick={() => openEditDialog(record)}>
                                        <EditIcon />
                                    </Button>
                                    <Button color="error" onClick={() => handleDelete(record.id)}>
                                        <Delete />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle>{currentRecord.id ? 'Edit Listing Request' : 'Add Listing Request'}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={currentRecord}
                        onSubmit={(values) => handleAddOrEdit({ ...values, personId })}
                    >
                        {({ handleChange, values }) => (
                            <Form>
                                <TextField
                                    margin="dense"
                                    label="Title"
                                    fullWidth
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Description"
                                    fullWidth
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Type"
                                    fullWidth
                                    name="type"
                                    value={values.type}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Status"
                                    fullWidth
                                    name="status"
                                    value={values.status}
                                    onChange={handleChange}
                                />
                                <TextField
                                    margin="dense"
                                    label="Completion Date"
                                    fullWidth
                                    name="completionDate"
                                    type="date"
                                    value={values.completionDate}
                                    onChange={handleChange}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <DialogActions>
                                    <Button onClick={() => setIsDialogOpen(false)}>Cancel</Button>
                                    <Button type="submit">{currentRecord.id ? 'Update' : 'Add'}</Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ListingRequests;
