import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SpeedIcon from '@mui/icons-material/Speed';
import DataUsageIcon from "@mui/icons-material/DataUsage";
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InsightsIcon from '@mui/icons-material/Insights';
import {
    ADMINDASHBOARD,
    NEW_APPLICATION,
    PROGRAMME_STATISTICS,
    REPORTS,
    USERPROFILE,
    VIEW_APPLICATION
} from "../../constants/routes";

export const listItem = [
    { icon: <SpeedIcon />, text: "Admin Dashboard", to: ADMINDASHBOARD },
    { icon: <GroupIcon />, text: "View Application", to: VIEW_APPLICATION },
    { icon: <GroupAddIcon />, text: "New Application", to: NEW_APPLICATION },
    { icon: <LeaderboardIcon />, text: "Reports", to: REPORTS },
    { icon: <InsightsIcon />, text: "Programme Statistics", to: PROGRAMME_STATISTICS },
];


export const userProfileAction = [
/*
    { text: "User Profile", to: USERPROFILE },
*/
    { text: "Logout", to: 'logout' },
];