// Import WhatsApp icon
import { WhatsApp } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    createTheme,
    Grid,
    IconButton,
    Paper,
    Stack,
    ThemeProvider,
    Typography,
    Fade
} from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { LabelWithDetails } from "../../../../components/LabelWithDetails";
import { ImmiCardsComp } from "../../../../components/immiCardsComp";
import { PassportListComp } from "../../../../components/passportListComp";
import Details from "../../../../components/details";
import SendEmail from "../../../../components/send-email";
import UploadImage from "../../../../components/Input/upload-image";
import Attachments from "../../../../components/attachments";
import Api, { httpErrorHandler } from "../../../../services/server/api";
import {
    formatMalaysianPhoneNumber,
    getAgeByYearOfBirth,
    getAgeCategoryByYearOfBirth,
    getColourByStatus,
    getGenderByChar,
} from "../../../../services/utils/helper";
import {
    applicationType,
    userType
} from "../../../../services/utils/types/types";
import { Edit } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import HealthInformation from "../../../../components/health-information";
import ListingRequests from "../../../../components/listing-requests";
interface PersonalDetailsProps {
    application?: applicationType;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#007bff",
        },
    },
    typography: {
        h6: {
            fontWeight: 600,
        },
    },
});

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    borderRadius: 12,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    color: theme.palette.text.secondary,
}));

const fadeIn = {
    animation: 'fadeIn 1.5s ease-in-out',
    '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 }
    }
};

export const PersonalDetails: React.FC<PersonalDetailsProps> = (props) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const queryParameters = new URLSearchParams(window.location.search);
    let userList: userType[] = [];
    let user: userType | undefined = {};

    if (props.application?.person) {
        userList = props.application.person;
        user = userList.find((person) => person.relationship === "MAIN APPLICANT");
    }

    const handleWhatsAppRedirect = () => {
        if (user?.mobileNo && user.mobileNo !== "-") {
            // Ensure the number starts with +60 or 60 and then append the rest of the digits
            let phoneNumber = user.mobileNo.replace(/\D/g, ""); // Remove non-digit characters

            // If the number starts with "0" (local format), replace it with "60" for the country code
            if (phoneNumber.startsWith("0")) {
                phoneNumber = "60" + phoneNumber.slice(1);
            }

            // Check if the phone number follows Malaysian standard of "60XXXXXXXXX"
            if (phoneNumber.startsWith("60") && phoneNumber.length >= 10 && phoneNumber.length <= 12) {
                const message = `Hi ${user.name || ""}, I am the admin from Mahar and I would like to reach out to you regarding your IMMI13 card status.`;
                const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
                window.open(whatsappURL, "_blank");
            } else {
                alert("Invalid phone number. Please provide a valid Malaysian mobile number.");
            }
        } else {
            alert("Invalid phone number. Please provide a valid mobile number.");
        }
    };

    const userID = user?.id || "";
    const immiList = user?.immi13 || [];
    const passportList = user?.passport || [];
    const healthInfoList = user?.health || [];
    const requestList = user?.request || [];



    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ m: 2, display: "flex", flexDirection: "column", gap: 2 }}>
                {loading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Fade in={!loading}>
                        <Box sx={{ flexGrow: 1, paddingLeft: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center", ...fadeIn
                                    }}>
                                        <Typography variant="h6" fontWeight="700" sx={{ margin: 1 }} color="primary">
                                        </Typography>
                                        <Box display={"flex"} gap={1} alignItems={"center"}>
                                            <Link to={`/user_profile/${userID}`}>
                                                <Button variant="contained" sx={{gap:1}} ><Edit/> Edit</Button>
                                            </Link>
                                            <Link to={`member/${userID}`}>
                                                <Button variant="contained" sx={{ gap:1}}><PersonIcon/>Member</Button>
                                            </Link>
                                            <SendEmail user={user} />
                                            <IconButton
                                                color="primary"
                                                sx={{ marginLeft: 2 }}
                                                onClick={handleWhatsAppRedirect}
                                            >
                                                <WhatsApp />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sx={{margin: 2, display: "flex", alignItems: "end"}}>
                                    <UploadImage user={user}/>
                                </Grid>

                                {/* Personal Information Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ margin: 2, marginTop: 4, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                        Personal Information
                                    </Typography>
                                </Grid>
                                <Details title="Full Name" children={user?.name || "-"} sx={{ ...fadeIn }} />
                                <Details title="Gender" children={user ? getGenderByChar(user.gender) : "-"} sx={{ ...fadeIn }} />
                                <Details title="Year of Birth" children={user?.yearOfBirth || "-"} sx={{ ...fadeIn }} />
                                <Details title="Age" children={getAgeByYearOfBirth(user?.yearOfBirth || "0")} sx={{ ...fadeIn }} />
                                <Details title="Age Category" children={getAgeCategoryByYearOfBirth(user?.yearOfBirth || "0")} sx={{ ...fadeIn }} />
                                <Details title="Mobile No." children={formatMalaysianPhoneNumber(user?.mobileNo) || "-"} sx={{ ...fadeIn }} />
                                <Details title="Email Address" children={user?.emailAddress || "-"} sx={{ ...fadeIn }} />


                                {/* Status Information Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ margin: 2, marginTop: 4, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                        Application Information
                                    </Typography>
                                </Grid>
                                <Details title="Applicant Status" children={user?.applicationStatus || "-"} scolour={getColourByStatus(user?.applicationStatus || "-")} sx={{ ...fadeIn }} />
                                <Details title="Calling Num" children={user?.callingNum || "-"} sx={{ ...fadeIn }} />
                                <Details title="Document" children={user?.typeOfDocument || "-"} sx={{ ...fadeIn }} />
                                <Details title="FILE NO." children={user?.fileNo || "-"} sx={{ ...fadeIn }} />

                                <Details title="SERIAL NO" children={user?.serielNo || "-"} sx={{ ...fadeIn }} />
                                <Details title="TYPE OF PASS" children={user?.typeOfPass || "-"} sx={{ ...fadeIn }} />

                                <Details title="UN Status (UNHCR Card No.)" children={user?.unStatus || "-"} sx={{ ...fadeIn }} />
                                <Details title="UNHRC Card Expiry Date" children={user?.unhrcCardExpiryDate || "-"} sx={{ ...fadeIn }} />
                                <Details title="Notes" children={user?.notes || "-"} sx={{ ...fadeIn }} />
                                <Details title="Note(s) / Remark(s)" children={user?.remarks || "-"} sx={{ ...fadeIn }} />

                                {/* Address Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ margin: 2, marginTop: 4, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                        Address
                                    </Typography>
                                </Grid>
                                <Details title="District" children={user?.address?.DISTRICT || "-"} sx={{ ...fadeIn }} />
                                <Details title="State" children={user?.address?.STATE || "-"} sx={{ ...fadeIn }} />
                                <Details title="Detailed Address" children={user?.address?.DETAIL_ADDRESS || "-"} sx={{ ...fadeIn }} />

                                {/* Education Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ margin: 2, marginTop: 4, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                        Education
                                    </Typography>
                                </Grid>
                                <Details title="Education Field" children={user?.education?.FIELD || "-"} sx={{ ...fadeIn }} />
                                <Details title="Level" children={user?.education?.LEVEL || "-"} sx={{ ...fadeIn }} />
                                <Details title="University" children={user?.education?.UNIVERSITY || "-"} sx={{ ...fadeIn }} />

                                {/* Employment Section */}
                                <Grid item xs={12}>
                                    <Typography variant="h5" fontWeight="bold" sx={{ margin: 2, marginTop: 4, color: "#2E3B55", borderBottom: "2px solid #2E3B55" }}>
                                        Employment
                                    </Typography>
                                </Grid>
                                <Details title="Details of Employer" children={user?.employment?.DETAILS_OF_EMPLOYER || "-"} sx={{ ...fadeIn }} />
                                <Details title="Position" children={user?.employment?.POSITION || "-"} sx={{ ...fadeIn }} />
                                <Details title="Salary" children={user?.employment?.SALARY || "-"} sx={{ ...fadeIn }} />


                            </Grid>
                            <div style={{marginBottom: 20}}></div>

                            {/* Passport and Immigration Info */}
                            <PassportListComp passportList={passportList} personID={userID}/>
                            <ImmiCardsComp immiCardList={immiList} personID={userID}/>
                            <ListingRequests  requestsList={requestList}
                                              personId={userID}/>
                            <HealthInformation healthInfoList ={healthInfoList}
                                               personId={userID}/>
                            <Attachments user={user}/>
                        </Box>
                    </Fade>
                )}
            </Box>
        </ThemeProvider>
    );
};