import axios from "axios";
import {getToken} from "../../provider/authProvider";
import {Navigate} from "react-router-dom";


export default class Api {
    constructor() {
        this.api_token = getToken();
        this.client = null;
        //this.api_url = "http://mahar-api-staging-1044181754.ap-southeast-1.elb.amazonaws.com"
          this.api_url = process.env.REACT_APP_API_ENDPOINT;

    }
    init = () => {
        let headers = {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*"
        };
        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }else{
            // Navigate to log out
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };


    postLogin = (data) => {
        return this.init().post("/auth/login", data);
    };

    getUserList = (params) => {
        return this.init().get("/application/person", { params: params });
    };

    getApplication = (params) => {
        return this.init().get("/application", { params: params });
    };

    addNewUser = (data) => {
        return this.init().post("/application/person", data);
    };


    //passport
    addNewPassport = (data) => {
        return this.init().post("/application/person/passport", data);
    };
    updatePassport = (passportId, data) => {
        return this.init().put("/application/person/passport/"+passportId, data);
    };


    //immiCards
    addNewCards = (data) => {
        return this.init().post("/application/person/immi13", data);
    };
    updateImmiCard = (cardId, data) => {
        return this.init().put("/application/person/immi13/"+cardId, data);
    };
    addNewApplication= (data) =>{
        return this.init().post("/application/new", data)
    };
    addNewPerson= (data) =>{
        return this.init().post("/application/person", data)
    };
    UpdatePerson= (data, id) =>{
        return this.init().put(`/application/person/${id}`, data)
    };
    getPerson= (id)=>{
        return this.init().get(`/application/person?id=${id}`)
    };
    deletePerson = (id) => {
        return this.init().delete(`/application/person?id=${id}`);
    };
    
        uploadFile=(id,data) =>{
            return this.init().post(`/application/person/file/${id}`, data)
        }
        sendEmail=(data) =>{
            return this.init().post(`/mail/send`, data)
        }
        
        deleteFile=(id) =>{
            return this.init().delete(`/application/person/file/${id}`)
        }


        // Health requests
    addHealthRequest = (data) => {
        return this.init().post("/application/person/health", data);
    };
    updateHealthRequest = (healtRrequestId, data) => {
        return this.init().put("/application/person/health/"+healtRrequestId, data);
    };
    deleteHealthRequest=(id) =>{
        return this.init().delete(`/application/person/health/${id}`)
    }

    //General requests
    addGeneralRequest = (data) => {
        return this.init().post("/application/person/request", data);
    };
    updateGeneralRequest = (requestId, data) => {
        return this.init().put("/application/person/request/"+requestId, data);
    };
    deleteGeneralRequest=(id) =>{
        return this.init().delete(`/application/person/request/${id}`)
    }


}

export function httpErrorHandler(error) {

    if (error === null) throw new Error('Unrecoverable error!! Error is null!')
    if (axios.isAxiosError(error)) {
        //here we have a type guard check, error inside this if will be treated as AxiosError
        const response = error?.response
        const request = error?.request
        const config = error?.config //here we have access the config used to make the api call (we can make a retry using this conf)

        if (error.code === 'ERR_NETWORK') {
            alert('connection problems..')
        } else if (error.code === 'ERR_CANCELED') {
            alert('connection canceled..')
        }
        console.log(response);
        if (response) {
            //The request was made and the server responded with a status code that falls out of the range of 2xx the http status code mentioned above
            const statusCode = response?.status
            if (statusCode === 404) {
                console.log('The requested resource does not exist or has been deleted')
            } else if (statusCode === 401) {
                delete axios.defaults.headers.common["Authorization"];
                localStorage.removeItem('token')
                window.location.reload();

            }else if(statusCode === 400){
                const message = response.data?.message;
                alert(message);
            }
        } else if (request) {
            //The request was made but no response was received, `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in Node.js
        }
    }
    //Something happened in setting up the request and triggered an Error
}