import {Avatar, Box, createTheme, Paper, ThemeProvider, Typography} from "@mui/material";
import * as React from "react";
import SimpleTable from "../../components/SimpleTable";
import Api from "../../services/server/api";

interface ViewApplicationProps {

}

const theme = createTheme();

export const ViewApplication: React.FC<ViewApplicationProps> = (props) => {

    return (
        <ThemeProvider theme={theme}>
            <Typography
                component="h1"
                variant="h5"
                color="inherit"
                noWrap
                sx={{ my: 4,
                    mx: 4 }}
            >
                View Applications
            </Typography>
            <Box
                sx={{
                    my: 4,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 200px)'
                }}
            >
                <Paper sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} elevation={2} >
                    <SimpleTable/>
                </Paper>
            </Box>
        </ThemeProvider>
    );
};